import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Provider } from '../../AppContext.js'
import { util } from 'amway-lc-fn'
const { catchJsToken, setEnvironment } = util
// import { catchJsToken, setEnvironment } from '../../../utils/util'

function LcWrap(props) {
  useEffect(() => {
    setEnvironment(props.lcEnv)
    window.sessionStorage.setItem('lc-channelId', props.channelId)
    if (props.jsToken) catchJsToken(props.jsToken)
  }, [props])
  return (
    <Provider value={props.lcEnv}>
      {...props.children}
    </Provider>
  )
}

LcWrap.propTypes = {
  lcEnv: PropTypes.string,
  channelId: PropTypes.string,
  appId: PropTypes.string,
  jsToken: PropTypes.string
}
LcWrap.defaultProps = {
  lcEnv: 'prod',
  channelId: 'hybris',
  appId: '',
  jsToken: ''
}
export default LcWrap
