import React from 'react'
import { Consumer } from '../AppContext.js'
import LoginHead from './LoginHead/index.jsx'
import SmsForm from './SmsForm/index.jsx'
import PwdForm from './PwdForm/index.jsx'
import LoginFoot from './LoginFoot/index.jsx'
import './index.less'
import BindMobile from '../BindMobile/index.jsx'
// import { catchTokens } from '../../utils/util'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state: 0, // 0 登录，1绑定手机
      // isInit: true,
      loginType: 'pwd',
      errorText: null,
      bindInfo: undefined
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   const { shareAda } = props
  //   const { isInit } = state
  //   return shareAda && isInit ? { loginType: 'sms', isInit: false } : null
  // }

  updateParent=(key, value) => {
    this.setState({
      [key]: value
    })
  }

  changeLogin=(loginType) => {
    this.setState({ loginType, errorText: '' })
  }

  loginSuccess=(res) => {
    this.props.onLoginSuccess(res)
  }

  back = () => {
    this.setState({ state: 0 })
  }

  render() {
    const { loginType, errorText, state, bindInfo } = this.state
    const { spouseFOA, socialLogin } = this.props
    const errDom = <div className={`login-error-text lc-errorMsg ${errorText ? 'show' : 'hide'}`}><div>{errorText}</div></div>
    return (
      <Consumer>
        {
          value => {
            return state === 0 ? <div className="loginCenter-main-page">
              <LoginHead loginType={loginType} changeLogin={this.changeLogin}></LoginHead>
              <PwdForm visibel={loginType === 'pwd'} store={value} errDom={errDom} {...this.props} onLoginSuccess={(res) => { this.loginSuccess(res) }} updateParent={this.updateParent}></PwdForm>
              <SmsForm visibel={loginType !== 'pwd'} store={value} errDom={errDom} {...this.props} onLoginSuccess={(res) => { this.loginSuccess(res) }} updateParent={this.updateParent}></SmsForm>
              <LoginFoot store={value} socialLogin={socialLogin} spouseFOA={spouseFOA} changeLogin={this.changeLogin} type={loginType} lineConfig={this.props.lineConfig}></LoginFoot>
            </div>
              : <BindMobile hasSuccessPage={true} ada={value.userInfo.ada} isMaster={bindInfo.isMaster} {...this.props} onBack={this.back} onSuccess={this.props.onBack}></BindMobile>
          }
        }

      </Consumer>
    )
  }
}

export default Login
