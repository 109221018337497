// @flow
import React, { useContext } from 'react'
import './index.less'
import { Context } from '../../AppContext.js'
const TipWrap = (props) => {
  const { setVisible, personalInfo } = useContext(Context)

  const onMore = () => {
    setVisible(true)
  }
  return (
    <div className={`${props.className} lc-TipWrap`} style={props.style}>
      <div className="lc-TipWrap-ctx">
        <div>{personalInfo !== null ? personalInfo.consent_information : '請務必詳閱並同意最新【個人資料告知暨同意事項】。當您使用本服務時，本公司視為您已閱讀並同意前述個資告知內容。'}</div>
        <b onClick={onMore}>閱讀更多</b>
      </div>
    </div>
  )
}
export default TipWrap
