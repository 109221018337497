import React, { useState } from 'react'
import './index.less'

function LoginHead(props) {
  const [tabs] = useState([
    { label: '一般登入', value: 'pwd' },
    { label: '簡訊驗證', value: 'sms' }
  ])
  const tabClick = (item) => {
    props.changeLogin(item.value)
  }
  return (
    <div className="loginCenter-head">
      {/* <h2>登錄</h2> */}
      <div className="loginCenter-head-tabs">
        {
          tabs.map(item => {
            return <div onClick={() => { tabClick(item) }} key={item.value}
              className={`${props.loginType === item.value ? 'active' : ''} loginCenter-head-tabs-item`}>{item.label}</div>
          })
        }
      </div>
    </div>
  )
}

export default LoginHead
