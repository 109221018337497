import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import './index.less'

class Dialog extends Component {
  constructor(props) {
    super(props)
    this.node = document.createElement('div')
    document.body.appendChild(this.node)
  }

  componentWillUnmount() {
    document.body.removeChild(this.node)
  }

    confirm = () => {
      if (this.props.confirmAble) {
        this.props.confirm()
      }
    }

    cancel = () => {
      if (this.props.close) {
        this.props.close()
        return
      }
      this.props.cancel()
    }

    render() {
      const { visible, title, showTitle, content, confirmText, cancelText, children, confirmAble, showCancel, className, cancel } = this.props
      return createPortal(
        <div className={`amyWay-dialog ${className || ''}`} style={{ visibility: visible ? 'visible' : 'hidden' }}>
          <div className="amyWay-dialog-wrap bottom-dialog">
            <div className="amyWay-dialog-topline"></div>
            {showTitle || showTitle === undefined ? <h3>{title}</h3> : ''}
            {showCancel && <i className="iconfont icon-close_light close" onClick={this.cancel}></i>}
            <div className="amyWay-dialog-con">
              <div className="amyWay-dialog-text" dangerouslySetInnerHTML={{ __html: content }}></div>
              {children}
              <div className="amyWay-dialog-footer">
                {showCancel && <div onClick={cancel} className="amyWay-dialog-footer-btn cancelBtn">{cancelText}</div>}
                <div onClick={this.confirm} className={`amyWay-dialog-footer-btn confirmBtn ${confirmAble ? '' : 'disable'}`}>{confirmText}</div>
              </div>
            </div>

          </div>
        </div>,
        this.node
      )
    }
}
Dialog.propTypes = {
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  content: PropTypes.string,
  confirmText: PropTypes.string,
  confirmAble: PropTypes.bool,
  showCancel: PropTypes.bool,
  cancelText: PropTypes.string
}
Dialog.defaultProps = {
  title: '提醒',
  showTitle: true,
  content: '',
  confirmText: '確定',
  cancelText: '取消',
  confirmAble: true,
  showCancel: true
}
export default Dialog
