import React from 'react'
import Dialog from '../../Dialog/index.jsx'
import PropTypes from 'prop-types'
import './index.less'

function TipDialog(props) {
  const { visible, confirmText, step, contentStackData, confirm, cancel, isVisitor } = props
  return <Dialog
    className="tipDialog"
    title='個資告知'
    confirmText={confirmText}
    cancelText="返回"
    showCancel={step === 0}
    confirmAble={(step === 0 && contentStackData && contentStackData.moreDetails) || step === 1}
    confirm={confirm}
    cancel={cancel}
    visible={visible}
  >
    <div className='tipDialog-dialog-ctx'>
      {
        step === 0
          ? <div className='tipDialog-dialog-ctx-tip'>
            <div className='tipDialog-dialog-ctx-tip-title' dangerouslySetInnerHTML={contentStackData ? { __html: contentStackData.consentInformation } : { __html: '請務必詳閱並同意以下【<span>個人資料告知暨同意事項</span>】。當您使用本服務時，本公司視為您已閱讀並同意個資告知內容。' }}></div>
            <div className='tipDialog-dialog-ctx-tip-ctx' dangerouslySetInnerHTML={contentStackData ? { __html: contentStackData.moreDetails } : { __html: '' }}></div>
          </div>
          : step === 1 && isVisitor ? <p className='tipDialog-dialog-ctx-ok'>您已成功登入！您將以<b>訪客身份</b>進行購物 </p> : ''
      }
    </div>
  </Dialog>
}
Dialog.propTypes = {
  visible: PropTypes.bool,
  isVisitor: PropTypes.bool,
  step: PropTypes.number,
  confirmText: PropTypes.string,
  contentStackData: PropTypes.object,
  confirm: PropTypes.func,
  cancel: PropTypes.func
}
Dialog.defaultProps = {
  visible: false,
  isVisitor: false,
  step: 0,
  confirmText: '同意',
  contentStackData: undefined,
  confirm: () => {},
  cancel: () => {}
}
export default TipDialog
