import React, { Component } from 'react'
import './index.less'

export default function FormCreate(Cmp) {
  return class extends Component {
    constructor(props) {
      super(props)
      this.options = {} // 存储配置字段项
      this.state = {} // 存储字段值
    }

        handleChange=(e) => {
          const { name, value } = e.target
          if (this.options[name] && this.options[name].format) {
            this.setState({ [name]: this.options[name].format(value) })
          } else {
            this.setState({ [name]: value })
          }
        }

        getFieldDecorator=(field, option) => {
          if (option) this.options[field] = option
          return InputCmp => <div className={`lc-form-item ${this.state[`${field}ErrMsg`] ? 'err-form-item' : ''}`}>
            { // 由React.createElement生成的元素不能修改，需要克隆一份再扩展
              React.cloneElement(InputCmp, {
                name: field,
                value: this.state[field] || '',
                onChange: this.handleChange
              })
            }
            <p className='lc-form-item-err'>{this.state[`${field}ErrMsg`]}</p>
          </div>
        }

        getFieldsValue=() => {
          return { ...this.state }
        }

        setFieldValue=(field, value) => {
          return this.setState({ [field]: value })
        }

        getFieldValue=(field) => {
          return this.state[field] || ''
        }

        validateFields=(cb) => {
          const tmp = {}
          let valid = true
          for (const field in this.options) {
            if (this.options[field] && this.options[field].rules) {
              const rules = this.options[field].rules
              if (rules && Array.isArray(rules)) {
                for (const rule of rules) {
                  if (!this.validateRule(rule, field)) {
                    valid = false
                    break
                  }
                }
              }
            }
            tmp[field] = this.state[field] || ''
          }
          cb(valid, tmp)
        }

        validateRule=(rule, field) => {
          const { required, pattern, message, validator } = rule
          if (required && !this.state[field]) {
            this.setState({ [`${field}ErrMsg`]: message })
            return false
          }
          if (pattern && !pattern.test(this.state[field])) {
            this.setState({ [`${field}ErrMsg`]: message })
            return false
          }
          if (validator) {
            const msg = validator(rule, this.state[field], (message) => {
              return message
            })
            if (msg) {
              this.setState({ [`${field}ErrMsg`]: msg })
              return false
            }
          }
          this.setState({ [`${field}ErrMsg`]: '' })
          return true
        }

        render() {
          return (
            <Cmp {...this.props}
              getFieldDecorator={this.getFieldDecorator}
              getFieldsValue={this.getFieldsValue}
              getFieldValue={this.getFieldValue}
              setFieldValue={this.setFieldValue}
              validateFields={this.validateFields}
            ></Cmp>
          )
        }
  }
}
