import React, { useState, useEffect, useContext } from 'react'
import './index.less'
import pic from '../assets/images/h5/Sent@2x.png'
import LcTitle from '../module/LcTitle/index.jsx'
import FormCreate from '../FormCreate/index.jsx'
import LcInput from '../module/LcInput/index.jsx'
import YearSelect from '../module/YearSelect/index.jsx'
import { Context } from '../AppContext.js'

// import { checkForgetPassword } from '../../utils/api.js'
import { api } from 'amway-lc-fn'
const { checkForgetPassword } = api

const ForgetPassword = (props) => {
  const [errorMsg, setErrMsg] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [step, setStep] = useState(0)
  const { setState } = useContext(Context)
  const { getFieldDecorator, getFieldValue, validateFields } = props
  useEffect(() => {

  }, [])

  const back = () => {
    setState('Login')
  }
  const dataChange = (value) => {
    setDateOfBirth(value)
  }
  const submit = () => {
    setErrMsg('')
    if (!getFieldValue('ada') || !getFieldValue('idCard') || !dateOfBirth) return
    validateFields((valid, values) => {
      if (valid) {
        let errorMsg = ''
        checkForgetPassword({ ada: values.ada, idCard: values.idCard, dateOfBirth }).then(res => {
          if (res.ada) { // success
            setStep(1)
            errorMsg = ''
          } else if (res.code === 'LC.P.PWD_RESET_SEND_EXCEPTION.01') {
            errorMsg = '發送重置簡訊/郵件失敗，請確認是否有綁定手機號碼或設定郵箱'
          } else if (res.message === 'err.pwdManage.notFound.ada') {
            errorMsg = '資料驗證無效，請重新確認'
          } else if (res.message === 'err.pwdManage.notMatch' || res.message === 'err.pwdManage.notMatch' || res.message === 'err.pwdManage.notMatch.idCard') {
            errorMsg = '資料驗證無效，請重新確認'
          } else if (res.message === 'err.pwdManage.notFound.contact') {
            errorMsg = '您未設置手機號碼/email，詳情請洽客服(03)353-7800'
          } else if (res.message === 'err.param.password.isEmpty') {
            errorMsg = '您需要開卡後才能進行該操作'
          } else {
            errorMsg = '系統連線錯誤，請稍後再試'
          }
          setErrMsg(errorMsg)
        }).catch(err => {
          console.log(err)
          errorMsg = '系統連線逾時，請重新整理頁面後再試一次'
          setErrMsg(errorMsg)
        })
      }
    })
  }
  const adaValidator = (rule, value, callback) => {
    if (value.match(/^(0)/g) || value.replace(/\s/g, '').length !== 7) {
      return callback('輸入錯誤')
    }
  }
  const idCardValidator = (rule, value, callback) => {
    if (value.length < 8) {
      return callback('輸入錯誤')
    }
  }
  return (
    <div className='lc-commonWrap lc-forgetPassword'>
      {
        step === 0
          ? <div>
            <LcTitle title="忘記密碼"></LcTitle>
            <div className="loginCenter-form">
              <div className="loginCenter-form-item">
                <p className="loginCenter-form-item-label">安麗編號</p>

                {getFieldDecorator('ada', { rules: [{ validator: adaValidator }] })(<LcInput className="loginCenter-form-item-input" maxLength="7" type="tel"
                  placeholder="請輸入安麗編號" autoComplete="off" clearable></LcInput>)}
              </div>
              <div className="loginCenter-form-item">
                <p className="loginCenter-form-item-label">身份證字號</p>
                {getFieldDecorator('idCard', { rules: [{ validator: idCardValidator }] })(<LcInput className="loginCenter-form-item-input" placeholder="請輸入身分證字號"
                  autoComplete="off" maxLength="10" clearable></LcInput>)}
              </div>
              <div className="loginCenter-form-item">
                <p className="loginCenter-form-item-label">民國出生年月日</p>
                <YearSelect onChange={(val) => { dataChange(val) }}></YearSelect>
              </div>
              {
                errorMsg ? <p className="lc-errorMsg">{errorMsg}</p> : ''
              }
              <div className="loginCenter-form-item">
                <div onClick={submit} className={`loginCenter-form-item-btn ${getFieldValue('ada') && getFieldValue('idCard') && dateOfBirth ? 'loginCenter-form-item-btn-active' : ''}`}>驗證身份</div>
              </div>
            </div>
          </div>
          : <div className="lc-page-success">
            <div className='success-img'>
              <img src={pic} style={{ width: '50%' }} alt='' />
            </div>
            <h4>已發送簡訊/Email給您，請透過簡訊/Email內的連結設定新密碼</h4>
            <div onClick={back} className="sucees-btn">
            返回登入頁面
            </div>
          </div>
      }

    </div>
  )
}
export default FormCreate(ForgetPassword)
