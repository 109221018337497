import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './index.less'
import pic from '../assets/images/h5/Password@2x.png'
import LcTitle from '../module/LcTitle/index.jsx'
import FormCreate from '../FormCreate/index.jsx'
import LcInput from '../module/LcInput/index.jsx'
import { api, util } from 'amway-lc-fn'
const { checkToken, setNewPwd } = api
const { getUrlKey } = util

const ResetPassword = (props) => {
  const [errorMsg, setErrMsg] = useState('')
  const [token, setToken] = useState('')
  const [pageState, setPageState] = useState(-1)
  const { getFieldDecorator, getFieldValue, validateFields } = props

  useEffect(() => {
    setToken(getUrlKey(window.location.href, 'token'))
    if (token) checkPwdToken()
  }, [token])

  const checkPwdToken = () => {
    checkToken({ token }).then(res => {
      setPageState(res === true ? 1 : 0)
    }).catch(err => {
      console.log(err)
      setPageState(0)
    })
  }

  const submit = () => {
    setErrMsg('')
    if (getFieldValue('newPassword').length <= 5 || getFieldValue('confirmPassword').length <= 5) return
    validateFields((valid, values) => {
      if (valid) {
        let errorMsg = ''
        setNewPwd({ token, newPassword: values.newPassword }).then(res => {
          console.log(res)
          if (res && typeof res === 'string') { // success
            setPageState(2)
            errorMsg = ''
            props.onSuccess(res)
          } else if (res.message === 'err.param.newPassword.isNotLegal') {
            errorMsg = '密碼格式有誤，請輸入由數字及英文字母組成的6-8位密碼'
          } else if (res.message === 'err.param.newPassword.isSameOrSeriesDigit') {
            errorMsg = '新密碼過於簡單，請重新設置'
          } else if (res.message === 'err.restPwd.invalidToken') {
            errorMsg = '系統連線錯誤，請稍後再試'
          } else if (res.message === 'err.queryABOAccountByAda.isNull') {
            errorMsg = '系統連線錯誤，請稍後再試'
          } else if (res.message === 'err.newPassword.setSimple') {
            errorMsg = '新密碼過於簡單，請重新設置'
          } else if (res.message === 'err.pwdModify.update.password.fail') {
            errorMsg = '系統連線錯誤，請稍後再試'
          } else if (res.message === 'err.pwdManage.sameAsOldPwd') {
            errorMsg = '新密碼不能與舊密碼設定相同，請重新設定'
          } else if (res.message === 'err.pwdModify.invalidOldPassword') {
            errorMsg = '舊密碼輸入錯誤'
          } else {
            errorMsg = '系統連線錯誤，請稍後再試'
          }
          setErrMsg(errorMsg)
        }).catch(err => {
          console.log(err)
          errorMsg = '系統連線逾時，請重新整理頁面後再試一次'
          setErrMsg(errorMsg)
        })
      }
    })
  }
  const newPasswordValidator = (rule, value, callback) => {
    if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/.test(value) || !/[a-zA-Z]/g.test(value)) {
      return callback('密碼格式有誤，請輸入由數字及英文字母組成的6-8位密碼')
    }
    if (value.length > 8) {
      return callback('密碼格式有誤，請輸入由數字及英文字母組成的6-8位密碼')
    }
  }
  const confirmPasswordValidator = (rule, value, callback) => {
    if (getFieldValue('newPassword') !== value) {
      return callback('密碼不一致，請重新輸入')
    }
  }

  return (
    <div className='lc-commonWrap lc-resetPassword'>
      <LcTitle showBack={false} title="重設密碼"></LcTitle>
      {
        pageState === 1 ? <div className="loginCenter-form">
          <div className="loginCenter-form-item">
            <p className="loginCenter-form-item-label">新密碼</p>

            {getFieldDecorator('newPassword', { rules: [{ validator: newPasswordValidator }] })(<LcInput className="loginCenter-form-item-input" maxLength="8" type="password"
              placeholder="請輸入新密碼" autoComplete="off" clearable></LcInput>)}
          </div>
          <p className={`lc-resetPassword-checktip ${getFieldValue('newPassword').replace(/\s/g, '').length > 5 ? 'tip-active' : ''}`}><i className="iconfont icon-ok"></i>6-8個字符</p>
          <p className={`lc-resetPassword-checktip ${/[a-zA-Z]/g.test(getFieldValue('newPassword')) ? 'tip-active' : ''}`}><i className="iconfont icon-ok"></i>至少1個英文字母</p>
          <div className="loginCenter-form-item">
            <p className="loginCenter-form-item-label">再次輸入新密碼</p>
            {getFieldDecorator('confirmPassword', { rules: [{ validator: confirmPasswordValidator }] })(<LcInput className="loginCenter-form-item-input" maxLength="8" type="password"
              placeholder="請再次輸入新密碼" autoComplete="off" clearable></LcInput>)}
          </div>
          {
            errorMsg ? <p className="lc-errorMsg">{errorMsg}</p> : ''
          }
          <div className="loginCenter-form-item">
            <div onClick={submit} className={`loginCenter-form-item-btn ${getFieldValue('newPassword').length > 5 && getFieldValue('confirmPassword').length > 5 ? 'loginCenter-form-item-btn-active' : ''}`}>核實</div>
          </div>
        </div>
          : pageState === 0 ? <div className="lc-resetPassword-lost">
          您好，連結已失效，請重新操作
          </div>
            : pageState === 2
              ? <div className="lc-page-success">
                <div className='success-img'>
                  <img src={pic} style={{ width: '50%' }} alt='' />
                </div>
                <h4>成功重置密碼</h4>
                <p>您的密碼已成功重置 下次使用此密碼登錄</p>
                <div onClick={props.onSuccess} className="sucees-btn">
                返回登入頁面
                </div>
              </div> : ''
      }

    </div>
  )
}
ResetPassword.propTypes = {
  onSuccess: PropTypes.func,
  onBack: PropTypes.func
}
ResetPassword.defaultProps = {
  onSuccess: () => {},
  onBack: () => {}
}
export default FormCreate(ResetPassword)
