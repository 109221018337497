import React, { Component } from 'react'
import './index.less'

class LoginType extends Component {
    changeLogin=(type) => {
      this.props.changeLogin(type)
    }

    getSmsText=() => {
      if (this.props.spouseFOA) {
        return '免密碼快速登入'
      } else {
        return '免密碼登入（直銷商/會員）'
      }
    }

    toLine=() => {
      const { response_type, client_id, redirect_uri, state, scope } = this.props.lineConfig
      const _state = Math.floor(Math.random() * 10000000000)
      const url = 'https://lc-qa.amwaynet.com.tw/alc/logincenter/lineBinding/index'
      const lineConfig = {
        response_type: response_type || 'code',
        client_id: client_id || '1655268454',
        redirect_uri: redirect_uri || url,
        state: state || _state,
        scope: scope || 'profile%20openid%20email%20openid&nonce=09876xyz'
      }

      let redirectUrl = 'https://access.line.me/oauth2/v2.1/authorize'
      Object.keys(lineConfig).map((item, index) => {
        const s = index === 0 ? '?' : '&'
        if (item === 'redirect_uri') {
          redirectUrl += `${s}${item}=${encodeURIComponent(lineConfig[item])}`
        } else {
          redirectUrl += `${s}${item}=${lineConfig[item]}`
        }
      })
      window.location.replace(redirectUrl)
    }

    visitorLogin=() => {
      this.props.store.setState('visitorCheckout')
    }

    render() {
      const { type, socialLogin } = this.props
      return (
        <div className="login-type">
          {
            type === 'pwd' ? (
              <React.Fragment>
                {socialLogin === 'default' ? <div onClick={() => { this.toLine() }} className="line-login login-head">LINE登入</div> : ''}
                {/* <span className="and-text">非直銷商/會員/安麗之友</span>
                <div onClick={() => { this.visitorLogin() }} className="visitor-login">{socialLogin === 'line' ? '訪客綁定' : '訪客購物'}</div> */}
              </React.Fragment>
            )
              : ''
          }
          {/* <div className="login-type-register">
            <span>沒有賬戶？</span>
            <b onClick={() => { this.changePage('registerUrl') }}>註冊FOA</b>
          </div> */}
        </div>
      )
    }
}

export default LoginType
