import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './index.less'
class IdentityRadioGroup extends Component {
  componentDidMount() {
  }

    radioChange=(val, index) => {
      if (this.props.socialLogin === 'line' && val.isBindLine) return
      this.props.change(val, index)
    }

    render() {
      return (
        <div className="identity-radio-group">
          {
            this.props.data.map((item, index) => {
              return (
                <div className="identity-radio-item" key={item.personalId}>
                  <input disabled={this.props.socialLogin === 'line' && item.isBindLine}
                    id={'Identity-radio' + index} type="radio" name="Identity" value={item.personalId} onClick={() => { this.radioChange(item, index) }} />
                  <label className={`identity-radio-label ${this.props.socialLogin === 'line' && item.isBindLine ? 'identity-radio-label-disable' : ''}`}
                    htmlFor={'Identity-radio' + index}>{item.name}</label>
                </div>
              )
            })
          }

        </div>
      )
    }
}
IdentityRadioGroup.propTypes = {
  change: PropTypes.func,
  data: PropTypes.array
}
IdentityRadioGroup.defaultProps = {
  change: () => {},
  data: []
}
export default IdentityRadioGroup
