import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import env from './env';
import * as jssdk from 'amway-lc-fn'
import {LcWrap,LoginCenterSDK,ResetPassword,LineLogin,ModifyPassword,LineLoginByClient } from './components/index'
import {BindMobile } from './components/index'
// import {LoginIdentity} from './components/index'
console.log(2333,process.env,process.env.REACT_APP_ENV);

function App() {
  const [jsToken, setJsToken] = useState('');

  useEffect(() => {
    axios
      .post(env[process.env.REACT_APP_ENV].GET_TOKEN_URL)
      .then(async (res) => {
        setJsToken(res.data);
        // try {
        //   let LOGIN=await jssdk.util.ssoLogin({sessionToken:'f57364cf-e497-47c5-bf18-6103d87a3d8a', appToken:res.data.data})
        //   console.log(1111,LOGIN);
        // } catch (error) {
        //   console.log(2222,error);
        // }
      });
  }, []);
  const onLoginSuucess=(res)=>{
    console.log(1,res);
    if (window.parent) {
      window.parent.postMessage({
        type: 'loginSuccess',
        data: res
      }, '*');
    }
    // jssdk.util.refreshAccessToken()
  }
  const onLoginFailed=(res)=>{
    console.log(2,res);
    if (window.parent) {
      window.parent.postMessage({
        type: 'loginFailed',
        data: res
      }, '*');
    }
  }
  const onBack=()=>{
    console.log('onBack');
  }
  return (
    <div className="App">
      <LcWrap lcEnv={process.env.REACT_APP_ENV} channelId='hybris' jsToken={jsToken}>
        <div>

        
          <LoginCenterSDK shareAda="" lineConfig={
            {
              client_id:1656523399,
              redirect_uri:'https://shop-uat.amwaynet.com.tw/linecallback'
            }
          } onLoginSuccess={(res)=>{onLoginSuucess(res)}} onLoginFailed={(res)=>{onLoginFailed(res)}}></LoginCenterSDK>
          {/* <BindMobile ada='3753429' isMaster={false} onBack={onBack} onSuccess={onLoginSuucess}></BindMobile> */}

        {/* <LineLogin></LineLogin> */}
        <LineLoginByClient></LineLoginByClient>
        {/* <ResetPassword></ResetPassword> */}
        {/* <Register></Register> */}
        {/* <ModifyPassword onBack={()=>{console.log("aaaaa")}}></ModifyPassword> */}
        
        {/* <LoginIdentity onConfirm={confirm}></LoginIdentity> */}
        {/* <FormDemo></FormDemo> */}
        </div>
      </LcWrap>
    </div>
  );
}

export default App;
