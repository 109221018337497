// @flow
import React from 'react'
import './index.less'
import PropTypes from 'prop-types'
const LcTitle = (props) => {
  const back = () => {
    props.onBack()
  }
  return (
    <div className="lc-component-title">
      {
        props.showBack ? <i onClick={back} className="iconfont icon-icon_left"></i> : ''
      }
      {props.title}
    </div>
  )
}
LcTitle.propTypes = {
  title: PropTypes.string,
  showBack: PropTypes.bool,
  back: PropTypes.func
}
LcTitle.defaultProps = {
  title: '',
  showBack: true,
  onBack: () => {}
}
export default LcTitle
