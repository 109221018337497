// @flow
import React, { useContext } from 'react'
import { Context } from '../../AppContext.js'
import './index.less'
import PropTypes from 'prop-types'
const LcTitle = (props) => {
  const store = useContext(Context)
  const back = () => {
    props.onBack()
    try {
      store.setState('Login')
    } catch (error) {
    }
  }
  return (
    <div className="lc-component-title">
      {
        props.showBack ? <i onClick={back} className="iconfont icon-icon_left"></i> : ''
      }
      {props.title}
    </div>
  )
}
LcTitle.propTypes = {
  title: PropTypes.string,
  showBack: PropTypes.bool,
  back: PropTypes.func
}
LcTitle.defaultProps = {
  title: '',
  showBack: true,
  onBack: () => {}
}
export default LcTitle
