import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import './index.less'

const LcInput = (props) => {
  const [value, setValue] = useState('')
  const [type, setType] = useState('text')
  const [seePwd, setSeePwd] = useState(true)
  const [hasSee, setHasSeed] = useState(false)
  const textInput = useRef(null)
  /* eslint-disable */
  useEffect(() => {
    setValue(props.value)
    if(!hasSee) setType(props.type)
  }, [props.value])
  /* eslint-disable */
  const handleChange = (e) => {
    setValue(e.target.value)
    props.onChange(e)
  }
  const handleClick = (e) => {
    if (props.type === 'password') {
      setHasSeed(true)
      setSeePwd(!seePwd)
      if (seePwd) {
        setType('text')
      } else {
        setType('password')
      }
    } else if (props.clearable !== undefined) {
      setValue('')
      textInput.current.value = ''
      props.onChange({target:textInput.current})
    }
  }
  return (
    <div className={`lcInput ${props.className ? props.className :''}`}>
      <input
        {...props}
        type={type}
        placeholder=""
        clearable=""
        className='lcInput-input'
        onChange={handleChange}
        ref={textInput}
      />
      {
        props.type === 'password' ? <i
        className={`right-icon iconfont ${!value.length? 'icon-yanjing icon-yanjing-disabel' : value.length&&seePwd ?'icon-yanjing' : 'icon-yanjing1'}`}
        onClick={handleClick}
      ></i> :<i
          className={`right-icon iconfont ${props.clearable !== undefined && value.length ? 'icon-close_light' : 'hide'}`}
          onClick={handleClick}
        ></i>
      }
      <span
        className='place_holder'
        style={{
          display:
                  value === ''
                    ? 'inherit'
                    : 'none'
        }}
      >
        {props.placeholder || ''}
      </span>
    </div>
  )
}
LcInput.propTypes = {
  maxLength: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func
}
LcInput.defaultProps = {
  maxLength: '',
  type: '',
  onChange: () => {}
}
export default LcInput
