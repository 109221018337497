// @flow
import React, { useState, useEffect } from 'react'
import './index.less'
import PropTypes from 'prop-types'

import Login from '../Login/index.jsx'
import SmsForm from '../Login/SmsForm/index.jsx'
import ForgetAda from '../ForgetAda/index.jsx'
import ForgetPassword from '../ForgetPassword/index.jsx'
import ModifyPassword from '../ModifyPassword/index.jsx'
import InformationNotify from '../InformationNotify/index.jsx'
import VisitorCheckout from '../VisitorCheckout/index.jsx'
import LoginSuccess from '../Login/LoginSuccess/index.jsx'
import { Provider } from '../AppContext.js'
import { util } from 'amway-lc-fn'
const { catchTokens } = util

const LoginCenterSDK = (props) => {
  // const env = useContext(Context)
  const [visible, setVisible] = useState(false)
  const [personalInfo] = useState(null)
  const [userInfo, setUserInfo] = useState(null)
  const [state, setState] = useState('')
  const [store, setStore] = useState({ props, userInfo, setState, setVisible, personalInfo })

  useEffect(() => {
    if (props.shareAda) {
      setState('FOAlogin')
    } else {
      setState('Login')
    }
  }, [props.shareAda])

  const onLogin = async (userInfo) => {
    setUserInfo(userInfo)
    setStore({ props, userInfo, setState, setVisible, personalInfo })
    await catchTokens(userInfo.token)
  }
  const loginSuccess = (userInfo) => {
    props.onLoginSuccess(userInfo)
  }
  const onBack = () => {
    props.onLoginSuccess(store.userInfo)
    setState('Login')
  }
  const getUserType = () => {
    if (['TC', 'ABO'].includes(userInfo.distributorType)) return '安麗直銷商'
    if (['PM', 'SM'].includes(userInfo.distributorType)) return '安麗會員'
    if (userInfo.distributorType === 'FOA') {
      if (userInfo.sponsorNumber) return '安麗之友'
      return '訪客'
    }
    return '訪客'
  }

  return (
    <Provider value={store}>
      <div className="LoginCenterSDK">
        {
          state === 'Login' ? <Login {...props} onLogin={onLogin} onBack={onBack} onLoginSuccess={loginSuccess}></Login>
            : state === 'FOAlogin' ? <SmsForm FOA={true} {...props} store={store} onLogin={onLogin} onBack={onBack} onLoginSuccess={loginSuccess}></SmsForm>
              : state === 'ForgetAda' ? <ForgetAda></ForgetAda>
                : state === 'ForgetPassword' ? <ForgetPassword></ForgetPassword>
                  : state === 'ModifyPassword' ? <ModifyPassword ada={store.userInfo.ada} onSuccess={onBack}></ModifyPassword>
                    : state === 'visitorCheckout' ? <VisitorCheckout {...props} store={store} onLogin={onLogin} onBack={() => setState('Login')}></VisitorCheckout>
                      : state === 'registSuccess' ? <LoginSuccess confirm={onBack}></LoginSuccess>
                        : state === 'visitorSuccess' ? <LoginSuccess title={`您已成功登入!<br/>您將以${getUserType()}身分購物`}
                          text="安麗全系列商品幫助您迎向健康活力的每一天" confirm={onBack}></LoginSuccess>
                          : ''
        }
        <InformationNotify className={visible ? '' : 'hide'}></InformationNotify>
      </div>
    </Provider>
  )
}
LoginCenterSDK.propTypes = {
  lcEnv: PropTypes.string,
  socialLogin: PropTypes.string, // 区分lc和line登录
  code: PropTypes.string, // line登录的code
  spouseFOA: PropTypes.bool, // 是否支持FOA登录，用于显示个资告知
  scene: PropTypes.string,
  shareAda: PropTypes.string,
  unionid: PropTypes.string,
  lineConfig: PropTypes.object,
  onLoginSuccess: PropTypes.func,
  onLoginFailed: PropTypes.func
}
LoginCenterSDK.defaultProps = {
  lcEnv: 'prod',
  socialLogin: 'default',
  code: '',
  spouseFOA: false,
  scene: '',
  shareAda: '',
  unionid: '', // scene=register时必传，不能为空
  lineConfig: {
    response_type: '',
    client_id: '',
    redirect_uri: '',
    state: '',
    scope: ''
  },
  onLoginSuccess: () => {},
  onLoginFailed: () => {}
}
export default LoginCenterSDK
