import React from 'react'
import PropTypes from 'prop-types'
import pic from '../../assets/images/h5/Community@2x.png'

function LoginSuccess(props) {
  const { title, text, confirm } = props
  return (
    <div className='lc-commonWrap lc-page-success'>
      <div className='success-img'>
        <img src={pic} style={{ width: '50%' }} alt='' />
      </div>
      <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
      <p>{text}</p>
      <div onClick={confirm} className='sucees-btn'>
                繼續購物
      </div>
    </div>
  )
}
LoginSuccess.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  confirm: PropTypes.func
}
LoginSuccess.defaultProps = {
  title: '恭喜!<br/>您已註冊為安麗之友 ',
  text: '安麗全系列商品幫助您迎向健康活力的每一天',
  confirm: () => {}
}

export default LoginSuccess
