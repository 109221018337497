import React, { Component } from 'react'
import Recaptcha from 'react-google-invisible-recaptcha' // reaptcha V3
// import Reaptcha from 'reaptcha'
import LcTitle from '../../module/LcTitle/index.jsx'
// import TipWrap from '../../module/TipWrap/index.jsx'
import Dialog from '../../Dialog/index.jsx'
import TipDialog from '../TipDialog/index.jsx'
import FormCreate from '../../FormCreate/index.jsx'
import LcInput from '../../module/LcInput/index.jsx'
import './index.less'
import { api, util, errorCode, config } from 'amway-lc-fn'
// import { loginPhoneAndSms, loginSendSmsCode,loginLine,contentStackAgree,generateTemporaryFoa } from '../../../utils/api'
const { loginPhoneAndSms, loginSendSmsCode, loginLine, contentStackAgree, generateTemporaryFoa, getPersonalInformation } = api
const { replaceInputValue, getEnvironment } = util
class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sendMsgSuccess: false,
      setNcSuccess: true, // reaptcha V2 验证成功
      recaptchaType: null, // reaptcha V3
      isShowNc: false,
      countdown: 0,
      timer: null,
      errorText: '',
      visitorTipVisible: false,
      dialog: {
        isShow: false,
        step: 0,
        confirmText: '同意',
        data: undefined
      },
      loginInfo: null,
      params: {
        phoneNumber: '',
        smsCode: ''
      }
    }
  }

  componentWillUnmount() {
    this.setState = () => false
    if (this.state.timer) clearInterval(this.state.timer)
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit()
    }
  }

    validate=() => {
      const { validateFields, getFieldValue } = this.props
      if (!getFieldValue('phoneNumber')) return false
      let valide
      validateFields((valid, values) => {
        valide = valid
      })
      if (valide) {
        this.setState({ errorText: '' })
      }
      return valide
    }

    handleSendSmsCode=() => {
      if (this.state.countdown) return
      const valid = this.validate()
      if (valid) {
        this.setState({ recaptchaType: 'send' })
        this.recaptcha.execute() // v3
      } else {
        this.recaptcha.reset()
      }
      // this.setState({ isShowNc: true })
    }

    onVerify = () => {
      const valid = this.validate()
      if (valid) {
        this.setState({ setNcSuccess: true })
      } else {
        this.captcha.reset()
      }
    }

    onResolved = () => { // v3
      const { recaptchaType } = this.state
      const recaptchaToken = this.recaptcha.getResponse()
      if (recaptchaType === 'send') {
        this.loginSendSmsCode(recaptchaToken)
      }
      if (recaptchaType === 'submit') {
        this.loginAction(recaptchaToken)
      }
    };

    loginSendSmsCode = (recaptchaToken) => {
      const { getFieldValue, setFieldValue } = this.props
      const phoneNumber = getFieldValue('phoneNumber')
      const { scene } = this.props
      const _params = {
        phoneNumber: phoneNumber.replace(/\s/g, ''),
        recaptchaToken,
        scene
      }
      loginSendSmsCode(_params).then(res => {
        if (res.code !== undefined) {
          const errorText = errorCode[res.code] || errorCode[res.message] || '系統連線錯誤，請稍後再試'
          this.setState({ errorText })
        } else {
          if (res && res.smsCode) setFieldValue('smsCode', res.smsCode)
          this.startTimer()
          this.setState({ sendMsgSuccess: true })
        }
      }).catch(() => {
        this.setState({ errorText: errorCode.NETERROR })
      })
    }

    handleSubmit() {
      const { getFieldsValue } = this.props
      const { phoneNumber, smsCode } = getFieldsValue()
      if (!phoneNumber || !smsCode || !this.state.setNcSuccess) {
        this.recaptcha.reset()
        return
      }
      this.setState({ recaptchaType: 'submit' })
      this.recaptcha.execute() // v3
    }

    loginAction = (recaptchaToken) => {
      const { unionid, shareAda, onLoginFailed, getFieldsValue, visitor } = this.props
      const { phoneNumber, smsCode } = getFieldsValue()
      const { dialog } = this.state

      const _param = {
        phoneNumber: phoneNumber.replace(/\s/g, ''),
        smsCode,
        // countryCode:'123456',
        // scene,
        shareAda,
        recaptchaToken,
        unionid
      }
      let loginApi = loginPhoneAndSms
      if (this.props.socialLogin === 'line') {
        _param.code = this.props.code
      }
      if (visitor) {
        loginApi = generateTemporaryFoa
      } else if (this.props.socialLogin === 'line') {
        delete _param.recaptchaToken
        loginApi = loginLine
      }
      loginApi(_param).then(async res => {
        if (res && res.ada) {
          await this.props.onLogin(res)
          const isValid = await this.checkGreyAda(res.ada, onLoginFailed)
          if (!isValid) return
          this.setState({ errorText: '', loginInfo: res })

          if (res.contentStackData) {
            try {
              const data = await getPersonalInformation({ ada: res.ada })
              if (data && data.consentInformation) {
                dialog.data = data
                dialog.confirmText = '同意'
                dialog.isShow = true
              }
              this.setState({ dialog })
            } catch (err) {
              this.successHandler()
            }
          } else {
            this.successHandler()
          }
        } else {
          // if (res.code === 'LC.B.F_REGISTER_OR_LOGIN.01') { // 访客登录
          //   this.setState({ visitorTipVisible: true })
          // } else {
          const errorText = errorCode[res.code] || errorCode[res.message] || '系統連線錯誤，請稍後再試'
          this.setState({ errorText })
          // }
          onLoginFailed(res)
        }
      }).catch(err => {
        this.setState({ errorText: errorCode.NETERROR })
      })
    }

    checkGreyAda = async (ada, onLoginFailed) => {
      let isValid = false
      try {
        // 关闭白名单验证
        // const data = await checkContainAda({ ada })
        const data = { isContainAda: true }
        if (data.isContainAda === true) {
          isValid = true
        } else {
          this.setState({ errorText: errorCode.UPDATING })
          onLoginFailed(data)
        }
      } catch (error) {
        this.props.updateParent(
          'errorText',
          errorCode.NETERROR
        )
      }
      return isValid
    }

    successHandler = () => {
      const { shareAda, onLoginSuccess, visitor } = this.props
      const { loginInfo, dialog } = this.state
      dialog.isShow = false
      this.setState({ dialog })
      const forward = loginInfo.forward

      if (shareAda && forward && forward.isRegister) {
        this.props.store.setState('registSuccess')
      } else if (visitor) {
        this.props.store.setState('visitorSuccess')
      } else {
        onLoginSuccess(loginInfo)
      }
    }

    startTimer = () => {
      let countdown = 60
      const Timer = setInterval(() => {
        if (countdown === 0) {
          clearInterval(Timer)
          this.setState({ countdown: 0 })
        } else {
          this.setState({ countdown: countdown })
          countdown--
        }
      }, 1000)
      this.setState({ timer: Timer })
    }

    confirm = () => {
      this.setState({ visitorTipVisible: false })
      this.props.updateParent('loginType', 'pwd')
    }

    tipConfirm=async () => {
      const { loginInfo, dialog } = this.state
      try {
        const res = await contentStackAgree({ ada: loginInfo.ada, version: dialog.data.version })
        if (res && res.code !== undefined) {
          const errorText = errorCode[res.code] || errorCode[res.message] || '系統連線錯誤，請稍後再試'
          this.setState({ errorText })
        } else {
          this.successHandler()
        }
      } catch (error) {

      }
    }

    tipCancel=() => {
      const { dialog } = this.state
      dialog.isShow = false
      this.setState({ dialog })
    }

    render() {
      const { countdown, sendMsgSuccess, setNcSuccess, errorText, dialog, visitorTipVisible } = this.state
      const { visibel, visitor, FOA, store, socialLogin } = this.props
      const { getFieldDecorator, getFieldValue } = this.props
      const phoneValidator = (rule, value, callback) => {
        if (
          value && value.replace(/\s/g, '').substr(0, 2) === '09' &&
              value.replace(/\s/g, '').length === 10
        ) {
          callback()
        } else {
          return callback('手機號碼格式錯誤')
        }
      }
      return (
        <div className={`smsLogin lc-commonWrap ${FOA || visitor || visibel ? 'show' : 'hide'}`}>
          {visitor ? <LcTitle title="訪客購物" onBack={this.props.onBack}></LcTitle> : ''}
          {FOA ? <div className='foaTip'>
            首次登入需要進行手機號碼驗證，若您是安麗直銷商/會員，您可以
            <span onClick={() => this.props.store.setState('Login')} className='lc-link'>使用編號登入</span>
          </div> : ''}
          <div className="loginCenter-form">
            <div className="loginCenter-form-item flex-between" style={{ alignItems: 'start' }}>
              {getFieldDecorator('phoneNumber', { rules: [{ validator: phoneValidator }], format: replaceInputValue })(
                <LcInput className="loginCenter-form-item-input"
                  placeholder="請輸入手機號碼" type='tel' autoComplete="off" maxLength="12" clearable></LcInput>
              )}

              <div className={`get-code ${getFieldValue('phoneNumber') && !countdown ? 'active' : ''}`} onClick={this.handleSendSmsCode}>{sendMsgSuccess ? '重新發送' : '發送簡訊驗證碼'}</div>
            </div>

            {getFieldDecorator('smsCode', { rules: [] })(<LcInput className="loginCenter-form-item-input"
              placeholder="請輸入簡訊驗證碼" type='tel' autoComplete="off" maxLength="6" clearable onKeyDown={val => { this.onKeyDown(val) }}></LcInput>)}

            <div className={`send-code-success-text ${sendMsgSuccess && countdown ? '' : 'hide'}`}>
              <div className="left">
                <p>已發送簡訊驗證碼，如果沒有收到, {countdown}秒後您可以點選"重新發送"</p>
              </div>
            </div>
            <p className={`lc-errorMsg ${errorText ? 'show' : 'hide'}`}>{errorText}</p>
          </div>

          <div className={`reaptcha-wrap ${getFieldValue('phoneNumber') !== '' && sendMsgSuccess ? 'show' : 'hide'}`}>
            {/* {FOA || visitor || visibel ? <Reaptcha
              ref={e => (this.captcha = e)}
              sitekey={config.sitekey}
              onVerify={this.onVerify} /> : ''} */}
            {FOA || visitor || visibel ? <Recaptcha
              ref={e => (this.recaptcha = e)}
              sitekey={config.environment[getEnvironment()].sitekey}
              onResolved={this.onResolved} /> : ''}
          </div>
          {visitor ? <div className='visitor-tip'>
            <i className='iconfont icon-i-icon'></i>
            <p>您若有認識直銷商，建議與其洽詢購買；若無，您將以訪客身分繼續購物</p>
          </div> : ''}
          <div className={`login-btn ${getFieldValue('phoneNumber') !== '' && getFieldValue('smsCode') !== '' && setNcSuccess ? 'active' : ''}`} onClick={this.handleSubmit.bind(this)}>{visitor ? '繼續購物' : socialLogin === 'line' ? '綁定' : '登入'}</div>
          {/* {visitor ? <div className="line"></div> : <TipWrap style={{ margin: '30px 16px 0 16px' }}></TipWrap>} */}
          <Dialog
            title='手機號碼尚未註冊/綁定'
            confirmText='一般登入'
            cancelText="訪客購物"
            showCancel={true}
            confirmAble={true}
            content="若您是直銷商或生活會員請使用已綁定過的手機號碼，或改用「一般登入」使用編號及密碼登入。<br/>若您是訪客，請使用「訪客購物」。"
            confirm={this.confirm}
            cancel={() => { store.setState('visitorCheckout') }}
            close={() => { this.setState({ visitorTipVisible: false }) }}
            visible={visitorTipVisible}
          ></Dialog>
          <TipDialog isVisitor={visitor} visible={dialog.isShow} step={dialog.step} confirmText={dialog.confirmText}
            contentStackData={dialog.data} confirm={this.tipConfirm}
            cancel={this.tipCancel} close=''></TipDialog>
        </div>
      )
    }
}

export default FormCreate(LoginForm)
