import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './index.less'
class YearSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isError: false,
      showYear: false,
      showMonth: false,
      showDay: false,
      yearText: '',
      yearValue: '',
      monthText: '',
      monthValue: '',
      dayText: '',
      dayValue: '',
      yearList: [],
      monthList: [],
      dayList: [],
      isMobile: false,
      //   showBg: false,
      selectType: ''
    //   mobileSelectList: []
    }
  }

  componentDidMount() {
    this.init()
  }

    init= () => {
      const _now = new Date()
      const _yearBegin = 1912
      const _yearEnd = _now.getFullYear()
      const yearList = []
      for (let index = 0; index <= _yearEnd - _yearBegin; index++) {
        yearList.push({
          value: _yearEnd - index,
          text: `民國${_yearEnd - index - 1911}年`,
          isHover: false
        })
      }
      this.setState({
        yearList,
        yearValue: yearList[0].value,
        yearText: yearList[0].value - 1911,
        monthText: (_now.getMonth() + 1),
        monthValue: (_now.getMonth() + 1),
        dayText: _now.getDate(),
        dayValue: _now.getDate()
        // isMobile: window.screen.width <= 767
      }, () => {
        this.replaceMonthList()
        this.replaceDayList()

        this.setComponentValue()
      })
    }

    // hoverLi=(item)=>{
    //     console.log(111,item);
    //     item.isHover = true;
    // }
    // leaveLi=(item)=>{;
    //     item.isHover = false;
    // }
    showYearMethod=() => {
      const { showYear, selectType } = this.state
      this.setState({
        showYear: !showYear,
        showMonth: false,
        showDay: false
        // showBg: true
      })
      if (selectType !== 'year') {
        this.setState({
          selectType: 'year'
        //   mobileSelectList: yearList
        })
      }
    }

    showMonthMethod=() => {
      const { showMonth, selectType } = this.state
      this.setState({
        showMonth: !showMonth,
        showYear: false,
        showDay: false
        // showBg: true
      })
      if (selectType !== 'month') {
        this.setState({
          selectType: 'year'
        //   mobileSelectList: monthList
        })
      }
    }

    showDayMethod=() => {
      const { showDay, selectType } = this.state
      this.setState({
        showDay: !showDay,
        showYear: false,
        showMonth: false
        // showBg: true
      })
      if (selectType !== 'day') {
        this.setState({
          selectType: 'day'
        //   mobileSelectList: dayList
        })
      }
    }

    selectMobileSelectItem=(item) => {
      const { selectType } = this.state
      //   const mobileSelectList = this.state.mobileSelectList.filter((dataItem) => {
      //     dataItem.isHover = false
      //     return dataItem
      //   })
      item.isHover = true
      if (selectType === 'year') {
        this.selectYearMethod(item)
      } else if (selectType === 'month') {
        this.selectMonthMethod(item)
      } else if (selectType === 'day') {
        this.selectDayMethod(item)
      }
    //   this.setState({
    //     mobileSelectList,
    //     showBg: false
    //   })
    }

    selectYearMethod=(e) => {
      const { value } = e.target.dataset

      this.setState({
        yearText: value - 1911,
        yearValue: value * 1,
        showYear: false
      }, () => {
        this.replaceDayList()
        this.setComponentValue()
      })
    }

    selectMonthMethod=(e) => {
      const { value } = e.target.dataset
      this.setState({
        monthText: value,
        monthValue: value * 1,
        showMonth: false
      }, () => {
        this.replaceDayList()
        this.setComponentValue()
      })
    }

    selectDayMethod=(e) => {
      const { value } = e.target.dataset
      this.setState({
        dayText: value,
        dayValue: value * 1,
        showDay: false
      }, () => {
        this.setComponentValue()
      })
    }

    replaceMonthList=() => {
      const monthList = []
      // 处理月份列表
      for (let index = 1; index <= 12; index++) {
        monthList.push({
          value: index,
          text: index,
          isHover: false
        })
      }
      this.setState({ monthList })
    }

    replaceDayList=() => {
      let _dayTotal = 31
      const { monthValue, dayValue, yearValue } = this.state

      switch (monthValue) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          _dayTotal = 31
          break
        case 4:
        case 6:
        case 9:
        case 11:
          _dayTotal = 30
          if (dayValue === 31) {
            this.setState({
              dayValue: 1,
              dayText: '1'
            })
          }
          break
        case 2:
          if (dayValue >= 29 && yearValue % 4 !== 0) {
            this.setState({
              dayValue: 1,
              dayText: '1'
            })
          }
          _dayTotal = yearValue % 4 === 0 ? 29 : 28
          break
        default:;
      }
      const dayList = []
      // 处理每月天数的列表
      for (let index = 1; index <= _dayTotal; index++) {
        dayList.push({
          value: index,
          text: index,
          isHover: false
        })
      }
      this.setState({ dayList })
    }

    formateDate=(val) => {
      return val > 9 ? val : `0${val}`
    }

    setComponentValue = () => {
      const { monthValue, dayValue, yearValue } = this.state
      const _componentValue = yearValue + '-' + this.formateDate(monthValue) + '-' + this.formateDate(dayValue)
      this.props.onChange(_componentValue)
      //   this.$emit('onChangeCallback', new Date(_componentValue).Format('yyyy-MM-dd'));
    }

    render() {
      const { isError, showYear, isMobile, yearText, yearList, monthText, showMonth, monthList, showDay, dayText, dayList } = this.state
      return (
        <div className="selectMain">
          <div className={`fLeft select year-select ${isError ? 'error' : ''}`}>
            <div className={`placeholder ${showYear ? 'open-up' : ''}`} onClick={this.showYearMethod}>{yearText}</div>
            <i className={`iconfont icon-Down ${showYear ? 'open-up' : ''}`} onClick={this.showYearMethod}></i>
            <ul className={`${showYear && !isMobile ? 'ul-show' : ''}`}>
              {
                yearList.map((item, index) => {
                  return <li key={index}
                    className={`${item.isHover ? 'hover' : ''}`}
                    data-value={item.value}
                    onClick={(item) => { this.selectYearMethod(item) }}>{item.text}</li>
                })
              }
            </ul>
          </div>
          <div className={`fLeft select month-select ${isError ? 'error' : ''}`}>
            <div className={`placeholder ${showMonth ? 'open-up' : ''}`} onClick={this.showMonthMethod}>{monthText}</div>
            <i className={`iconfont icon-Down ${showMonth ? 'open-up' : ''}`} onClick={this.showMonthMethod}></i>
            <ul className={`${showMonth && !isMobile ? 'ul-show' : ''}`}>
              {
                monthList.map((item, index) => {
                  return <li key={index}
                    className={`${item.isHover ? 'hover' : ''}`}
                    data-value={item.value}
                    onClick={(e) => { this.selectMonthMethod(e) }}>{item.text}</li>
                })
              }
            </ul>
          </div>
          <div className={`fLeft select day-select ${isError ? 'error' : ''}`}>
            <div className={`placeholder ${showDay ? 'open-up' : ''}`} onClick={this.showDayMethod}>{dayText}</div>
            <i className={`iconfont icon-Down ${showDay ? 'open-up' : ''}`} onClick={this.showDayMethod}></i>
            <ul className={`${showDay && !isMobile ? 'ul-show' : ''}`}>
              {
                dayList.map((item, index) => {
                  return <li key={index}
                    className={`${item.isHover ? 'hover' : ''}`}
                    data-value={item.value}
                    onClick={(e) => { this.selectDayMethod(e) }}>{item.text}</li>
                })
              }
            </ul>
          </div>
          {/* {
                                isMobile && showBg ? <div className="bg" onClick={()=>{this.setState({showBg:false})}}></div> :''
                            }
                            {
                                isMobile && showBg ? <ul className="mobile-select ul-show fix-select">
                                    {
                                        mobileSelectList.map((item,index)=>{
                                            return <li key={index}
                                            data-value={item.value}
                                            onMouseOver={(item)=>{this.hoverLi(item)}}
                                            onMouseLeave={(item)=>{this.leaveLi(item)}}
                                            onClick={(e)=>{this.selectMobileSelectItem(e)}}>{item.text}</li>
                                        })
                                    }
                                </ul> :''
                            } */}

        </div>
      )
    }
}
YearSelect.propTypes = {
  onChange: PropTypes.func
}
YearSelect.defaultProps = {
  onChange: () => {}
}
export default YearSelect
