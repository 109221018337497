import React, { Component } from 'react'
import JSEncrypt from 'jsencrypt'
import Recaptcha from 'react-google-invisible-recaptcha' // reaptcha V3
import './index.less'
import FormCreate from '../../FormCreate/index.jsx'
import LcInput from '../../module/LcInput/index.jsx'
// import Reaptcha from 'reaptcha'
// import { config } from 'amway-lc-fn'
// import { loginPhoneAndPassword, loginAdaAndPassword, loginLine, contentStackAgree, getJwks, getPersonalInformation,checkContainAda } from '../../../utils/api'
// import { replaceInputValue, getUrlKey } from '../../../utils/util'
// import { errorCode } from '../../../utils/errorCode'
import Dialog from '../../Dialog/index.jsx'
import TipDialog from '../TipDialog/index.jsx'
import IdentityRadioGroup from '../../module/IdentityRadioGroup/index.jsx'
import { api, util, errorCode, config } from 'amway-lc-fn'
const { loginPhoneAndPassword, loginAdaAndPassword, loginLine, contentStackAgree, getJwks, getPersonalInformation, checkContainAda } = api
const { replaceInputValue, getEnvironment } = util

class pwdForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      encryptor: new JSEncrypt(),
      isShowNc: false,
      // bindType: 0, // 0 已绑定，1雙戶籍壹方已綁定另一方未綁定，2單戶籍或雙戶籍均未綁定
      loginBy: '', // ada,tel
      bindArr: [],
      loginInfo: undefined,
      dialog: {
        visible: false,
        type: '',
        title: '',
        content: '',
        confirmText: '立即變更',
        cancelText: '下次再說'
      },
      tipDialog: {
        isShow: false,
        step: 0,
        confirmText: '同意',
        data: undefined
      },
      bindSelect: undefined,
      selectUserIndex: -1,
      needModify: false,
      ncSuccess: true
    }
  }

  componentDidMount() {
    this.getJwks()
  }

    onKeyDown = (e) => {
      if (e.keyCode === 13) {
        this.handleSubmit()
      }
    }

    clearErr = () => {
      this.props.updateParent('errorText', '')
    }

    // 修改为公钥本地获取
    getJwks = () => {
      const _this = this
      getJwks().then(res => {
        _this.state.encryptor.setPublicKey(res)
      })
    }

    validate = () => {
      const { validateFields } = this.props
      let valide
      validateFields((valid) => {
        valide = valid
      })
      if (valide) {
        this.props.updateParent('errorText', '')
      }
      return valide
    }

    onVerify = () => {
      const valid = this.validate()
      if (valid) {
        this.setState({ ncSuccess: true })
      } else {
        this.captcha.reset()
      }
    }

    onResolved = () => { // v3
      const { loginBy } = this.state
      const { getFieldValue } = this.props
      const recaptchaToken = this.recaptcha.getResponse()
      const phoneNumber = getFieldValue('phoneNumber').replace(/\s/g, '')
      const password = getFieldValue('password')
      if (loginBy === 'tel') {
        this.handleLogin({
          phoneNumber,
          password: this.state.encryptor.encrypt(password),
          recaptchaToken
        })
      }
      if (loginBy === 'ada') {
        this.handleAdaLogin({
          ada: phoneNumber,
          password: this.state.encryptor.encrypt(password),
          recaptchaToken
        })
      }
    };

    handleSubmit = () => {
      this.clearErr()
      const { validateFields, getFieldValue } = this.props
      const { ncSuccess } = this.state
      if (getFieldValue('phoneNumber') === '' || getFieldValue('password') === '' || !ncSuccess) return
      validateFields((valid, values) => {
        if (valid) {
          if (values.phoneNumber.replace(/\s/g, '').length === 10) {
            this.setState({ loginBy: 'tel' })
            this.recaptcha.execute() // v3
          } else if (values.phoneNumber.replace(/\s/g, '').length === 7) {
            this.setState({ loginBy: 'ada' })
            this.recaptcha.execute() // v3
          }
        }
      })
    }

    checkGreyAda = async (ada, onLoginFailed) => {
      let isValid = false
      try {
        // 关闭白名单验证
        // const data = await checkContainAda({ ada })
        const data = {isContainAda:true};
        if (data.isContainAda === true) {
          isValid = true
        } else {
          this.props.updateParent(
            'errorText',
            errorCode.UPDATING
          )
          onLoginFailed(data)
        }
      } catch (error) {
        this.props.updateParent(
          'errorText',
          errorCode.NETERROR
        )
      }
      return isValid
    }

    handleLogin = (params) => {
      // 手机号登录
      const { tipDialog } = this.state
      const { onLoginFailed } = this.props
      let loginApi = loginPhoneAndPassword
      if (this.props.socialLogin === 'line') {
        loginApi = loginLine
        params.code = this.props.code
      }
      loginApi(params).then(async (res) => {
        if (res && res.ada) {
          await this.props.onLogin(res)
          const isValid = await this.checkGreyAda(res.ada, onLoginFailed)
          if (!isValid) return
          this.props.updateParent('errorText', '')
          this.setState({ loginInfo: res }, async () => {
            if (res.contentStackData) {
              try {
                const data = await getPersonalInformation({ ada: res.ada })
                if (data && data.consentInformation) {
                  tipDialog.confirmText = '同意'
                  tipDialog.data = data
                  tipDialog.isShow = true
                }
                this.setState({ tipDialog })
              } catch (err) {
                this.telLoginHandle()
              }
            } else {
              this.telLoginHandle()
            }
          })
        } else {
          const errMessage = errorCode[res.code] || errorCode[res.message] || '系統連線錯誤，請稍後再試'
          this.props.updateParent(
            'errorText',
            errMessage
          )
          onLoginFailed(res)
        }
      }).catch(err => {
        console.log(err)
        this.props.updateParent(
          'errorText',
          errorCode.NETERROR
        )
      })
    }

    telLoginHandle = () => {
      const { loginInfo } = this.state
      const { onLoginSuccess } = this.props
      const forward = loginInfo.forward

      if (forward && forward.isExpiredPwd) {
        this.showModifyPwd()
      } else {
        onLoginSuccess(loginInfo)
      }
    }

    handleAdaLogin = (params) => {
      // ada号登录
      const { onLoginFailed } = this.props
      let loginApi = loginAdaAndPassword
      if (this.props.socialLogin === 'line') {
        loginApi = loginLine
        params.code = this.props.code
      }
      loginApi(params).then(async (res) => {
        if (res && res.ada) {
          await this.props.onLogin(res)
          const isValid = await this.checkGreyAda(res.ada, onLoginFailed)
          if (!isValid) return
          const bindArr = res.usersInfo.filter((item) => {
            return !item.isBindPhone
          })
          this.setState({ bindArr, loginInfo: res, needModify: res.isExpiredPwd })
          this.props.updateParent('errorText', '')
          this.adaLoginHandle()
        } else {
          const errMessage = errorCode[res.code] || errorCode[res.message] || '系統連線錯誤，請稍後再試'
          this.props.updateParent(
            'errorText',
            errMessage
          )
          onLoginFailed(res)
        }
      }).catch(err => {
        this.props.updateParent(
          'errorText',
          errorCode.NETERROR
        )
      })
    }

    adaLoginHandle = () => {
      const { loginInfo, bindArr } = this.state
      const { onLoginSuccess } = this.props
      // if (loginInfo.mustChangedPwd === 'Y') {
      //   this.props.store.setState('ModifyPassword')
      // } else
      if (loginInfo.usersInfo && loginInfo.usersInfo.length > 1) {
        this.showSelectUser()
      } else if (loginInfo.isExpiredPwd) {
        this.showModifyPwd()
      } else if (bindArr.length && this.props.socialLogin === 'default') {
        this.showBindPhone()
      } else {
        onLoginSuccess(loginInfo)
      }
    }

    tipConfirm=async () => {
      const { loginInfo, loginBy, tipDialog } = this.state
      try {
        const res = await contentStackAgree({ ada: loginInfo.ada, version: tipDialog.data.version })
        if (res && res.code !== undefined) {
          const errorText = errorCode[res.code] || errorCode[res.message] || '系統連線錯誤，請稍後再試'
          this.setState({ errorText })
        } else {
          this.tipCancel()
          if (loginBy === 'tel') {
            this.telLoginHandle()
          } else {
            this.adaLoginHandle()
          }
        }
      } catch (error) {

      }
    }

    tipCancel=() => {
      const { tipDialog } = this.state
      tipDialog.isShow = false
      this.setState({ tipDialog })
    }

    showModifyPwd = () => {
      const { dialog } = this.state
      dialog.title = '密碼變更提醒'
      dialog.content = '您的密碼長時間未變更，為了您的資料安全，請立即變更密碼'
      dialog.confirmText = '立即變更'
      dialog.type = 'modifyPwd'
      dialog.visible = true
      this.setState({ dialog })
    }

    showSelectUser = () => {
      const { dialog } = this.state
      dialog.title = '登入選擇'
      dialog.content = '請選擇您要登入的帳號'
      dialog.confirmText = '確認'
      dialog.type = 'selectUser'
      dialog.visible = true
      this.setState({ dialog })
    }

    showBindPhone = () => {
      const { dialog, loginInfo } = this.state
      dialog.title = '綁定手機號碼 登入超便利'
      dialog.content = loginInfo.usersInfo && loginInfo.usersInfo.length === 1 ? '未來您可透過手機號碼輕鬆登入' : '未來您可透過手機號碼輕鬆登入，請選擇您要綁定的帳號'
      dialog.confirmText = '立即綁定'
      dialog.type = 'bindPhone'
      dialog.visible = true
      this.setState({ dialog })
    }

    onConfirm = () => {
      const { dialog, loginInfo, selectUserIndex, needModify } = this.state
      if (dialog.type === 'modifyPwd') {
        // 登入不成功，進入修改密碼頁
        this.props.store.setState('ModifyPassword')
        dialog.visible = false
        this.setState({ dialog })
      } else if (dialog.type === 'selectUser') {
        loginInfo.usersInfo = [loginInfo.usersInfo[selectUserIndex]]
        const bindArr = loginInfo.usersInfo.filter((item) => {
          return !item.isBindPhone
        })
        if (needModify) {
          this.showModifyPwd()
        } else if (bindArr.length && this.props.socialLogin === 'default') {
          this.showBindPhone()
        } else {
          dialog.visible = false
          this.setState({ dialog })
          this.props.onLoginSuccess(loginInfo)
        }
        this.setState({ bindArr, loginInfo, selectUserIndex: -1 })
      } else {
        // 跳轉至手機號綁定頁
        this.bindConfirm()
      }
    }

    bindConfirm = () => {
      const { loginInfo, dialog, bindSelect } = this.state
      let bindInfo
      if (loginInfo.usersInfo.length === 1) {
        bindInfo = loginInfo.usersInfo[0]
      } else if (bindSelect) {
        bindInfo = bindSelect
      }
      dialog.visible = false
      this.setState({ dialog })
      this.props.updateParent('bindInfo', bindInfo)
      this.props.updateParent('state', 1)
    }

    showDialog = () => {
      const { dialog } = this.state
      dialog.visible = true
      this.setState({ dialog })
    }

    selectUser = (info, index) => {
      this.setState({ selectUserIndex: index })
    }

    radioChange = (info) => {
      this.setState({ bindSelect: info })
      this.props.updateParent('bindInfo', info)
    }

    onCancel = () => {
      const { dialog, bindArr } = this.state
      dialog.visible = false
      this.setState({ dialog })
      if (['modifyPwd', 'selectUser'].includes(dialog.type) && bindArr.length > 0 && this.props.socialLogin === 'default') {
        this.showBindPhone()
      } else if (dialog.type === 'selectUser') {
        // 取消选择身份登录
      } else {
        this.props.onLoginSuccess(this.state.loginInfo)
      }
    }

    render() {
      const { dialog, bindArr, bindSelect, loginInfo, selectUserIndex, tipDialog, ncSuccess } = this.state
      const { visibel, errDom, socialLogin } = this.props
      const { getFieldDecorator, getFieldValue } = this.props
      const phoneValidator = (rule, value, callback) => {
        // 输入登录账户为手机号码 台湾安利号规则：以09开头且长度为10位的数字
        if (
          value.replace(/\s/g, '').substr(0, 2) === '09' &&
              value.replace(/\s/g, '').length === 10
        ) {
          callback()
          // 输入为台湾安利号码； 台湾ada安利号码规则：非0开头且长度为7位的数字
        } else if (
          value.replace(/\s/g, '').substr(0, 2) !== '09' &&
              value.replace(/\s/g, '').length === 10
        ) {
          return callback(errorCode['err.businesslogin.notLast6Ada'])
          // 输入为台湾安利号码； 台湾ada安利号码规则：非0开头且长度为7位的数字
        } else if (value.length === 7) {
          if (value.substr(0, 1) === '0') {
            return callback(errorCode['err.businesslogin.notLast6Ada'])
          }
          callback()
        } else if (
          value.replace(/\s/g, '').length < 7 ||
              (value.replace(/\s/g, '').length > 7 &&
                  value.replace(/\s/g, '').length !== 10)
        ) {
          return callback(errorCode['err.businesslogin.notLast6Ada'])
        }
      }
      const passwordValidator = (rule, value, callback) => {
        if (value.length < 5) {
          return callback(errorCode['err.bussineslogin.password.lessthen6'])
          // 密碼錯誤，密碼應為6-8位
        }
      }
      return (
        <div className={`${visibel ? 'show' : 'hide'}`}>
          <div className="pwdForm loginCenter-form" style={{ margin: '0 16px' }}>
            {getFieldDecorator('phoneNumber', { rules: [{ validator: phoneValidator }], format: replaceInputValue })(<LcInput className="loginCenter-form-item-input"
              placeholder="請輸入直銷商 / 會員編號或手機號碼" type='tel' autoComplete="off" maxLength="12" clearable></LcInput>)}

            {getFieldDecorator('password', { rules: [{ validator: passwordValidator }] })(<LcInput className="loginCenter-form-item-input"
              placeholder="請輸入密碼" type='password' autoComplete="off" maxLength="15" clearable onKeyDown={val => { this.onKeyDown(val) }}></LcInput>)}
            <div className={`reaptcha-wrap ${getFieldValue('phoneNumber') !== '' && getFieldValue('password') !== '' ? 'show' : 'hide'}`}>
              {/* <Reaptcha
                ref={e => (this.captcha = e)}
                sitekey={config.sitekey}
                onVerify={this.onVerify} /> */}
              {visibel ? <Recaptcha
                ref={e => (this.recaptcha = e)}
                sitekey={config.environment[getEnvironment()].sitekey}
                onResolved={this.onResolved} /> : ''}
            </div>
          </div>
          {errDom}

          <div className='bottom-list loginCenter-clearfix'>
            <div className='loginCenter-clearfix forgetBtns'>
              <span
                className='forget-info'
                onClick={() => {
                  this.props.store.setState('ForgetAda')
                }}
              >
                            忘記編號
              </span>
              <span
                className='forget-password'
                onClick={() => {
                  this.props.store.setState('ForgetPassword')
                }}
              >
                            忘記密碼
              </span>
            </div>
          </div>

          <div
            className={`login-btn ${
                        getFieldValue('phoneNumber') !== '' && getFieldValue('password') !== '' && ncSuccess
                            ? 'active'
                            : ''
                    }`}
            onClick={this.handleSubmit}
          >
            {socialLogin === 'line' ? '綁定' : '登入'}
          </div>
          {/* 彈窗 */}
          <Dialog
            title={dialog.title}
            content={dialog.content}
            confirmText={dialog.confirmText}
            showCancel={!['selectUser', 'modifyPwd'].includes(dialog.type) || (dialog.type === 'modifyPwd' && (loginInfo.isForcedChangePwd === 'N' || loginInfo.forward.isForcedChangePwd === 'N')) || (dialog.type === 'selectUser' && socialLogin === 'line')}
            confirmAble={(dialog.type === 'bindPhone' && (bindSelect !== undefined || (loginInfo.usersInfo && loginInfo.usersInfo.length === 1))) || dialog.type === 'modifyPwd' || (dialog.type === 'selectUser' && selectUserIndex > -1)}
            cancelText={dialog.cancelText}
            confirm={this.onConfirm}
            cancel={this.onCancel}
            close=''
            visible={dialog.visible}
          >
            {
              dialog.type === 'bindPhone' && loginInfo.usersInfo && loginInfo.usersInfo.length > 1
                ? <IdentityRadioGroup data={bindArr} change={this.radioChange}></IdentityRadioGroup>
                : dialog.type === 'selectUser' && loginInfo.usersInfo && loginInfo.usersInfo.length > 1
                  ? <IdentityRadioGroup socialLogin={this.props.socialLogin} data={loginInfo.usersInfo} change={this.selectUser}></IdentityRadioGroup> : ''
            }
          </Dialog>
          <TipDialog visible={tipDialog.isShow} step={tipDialog.step} confirmText={tipDialog.confirmText}
            contentStackData={tipDialog.data} confirm={this.tipConfirm}
            cancel={this.tipCancel} close=''></TipDialog>
        </div>
      )
    }
}

export default FormCreate(pwdForm)
