import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.less'
import LoginCenterSDK from '../LoginCenterSDK/index.jsx'
// import Infomation from './Infomation/index.jsx'
import Dialog from '../Dialog/index.jsx'
import logo from '../assets/images/h5/logo_big@2x.png'

// import { getJwtToken, bindLine } from '../../utils/api.js'
// import { getUrlKey, catchTokens } from '../../utils/util.js'
import { api, util } from 'amway-lc-fn'
const { getJwtToken, bindLine } = api
const { getUrlKey, catchTokens } = util

function LineLogin(props) {
  const [state, setState] = useState(-1)
  const [code, setCode] = useState('')
  const [visible, setVisible] = useState(false)
  const [content, setContent] = useState('')
  const [info, setInfo] = useState(undefined)

  useEffect(() => {
    setCode(getUrlKey(window.location.href, 'code'))
    if (code) checkJwtToken()
  }, [code])

  const checkJwtToken = () => {
    getJwtToken({ redirectUri: `${window.location.origin}${window.location.pathname}`, code }).then(res => {
      if (res && res.lineIdIsBinding) {
        catchTokens(res.token).then(() => {
          props.onLoginSuccess(res)
        })
      } else if (res && res.lineIdIsBinding === false) {
        setState(2)
      } else {
        setState(0)
      }
    }).catch(err => {
      setState(0)
    })
  }
  // const start = () => {
  //   setState(2)
  // }
  const onLogin = (res) => {
    if (res.isBindLine === true || res.usersInfo[0].isBindLine === true) { // 单户籍自动绑定
      setInfo(res)
      setState(3)
      return
    }
    const personalId = res.amwayId || res.personalId || res.usersInfo[0].personalId || res.usersInfo[0].amwayId
    const params = {
      code: code,
      personalId
    }
    bindLine(params).then(resp => {
      if (resp && resp.isBindingSuccess === true) {
        // props.onLoginSuccess(res)
        setInfo(res)
        setState(3)
      } else {
        setContent('系統連線錯誤，請稍後再試')
        setVisible(true)
      }
    }).catch(err => {
      setContent('系統連線逾時，請重新整理頁面後再試一次')
      setVisible(true)
    })
  }
  const back = () => {
    props.onLoginSuccess(info)
  }
  return (
    <div className='lc-LineLogin'>
      {
        state === 2 ? <LoginCenterSDK {...props} onLoginSuccess={onLogin} socialLogin="line" code={code}></LoginCenterSDK>
          // : state === 1 ? <Infomation start={start}></Infomation>
          : state === 0 ? <div className="lc-LineLogin-lost">
                您好，連結已失效，請重新操作
          </div>
            : state === 3 ? <div className="lc-commonWrap lc-page-success">
              <div className='success-img'>
                <img src={logo} alt='' />
              </div>
              <h4>恭喜您完成綁定！</h4>
              <div onClick={back} className="sucees-btn">
              返回
              </div>
            </div>
              : ''
      }
      <Dialog
        title="綁定失敗"
        content={content}
        confirmText="確定"
        showCancel={false}
        confirm={() => { setVisible(false) }}
        cancel={() => { setVisible(false) }}
        visible={visible}
      >
      </Dialog>
    </div>
  )
}
LineLogin.propTypes = {
  spouseFOA: PropTypes.bool, // 是否支持FOA登录，用于显示个资告知
  scene: PropTypes.string,
  unionid: PropTypes.string,
  onLoginSuccess: PropTypes.func
}
LineLogin.defaultProps = {
  spouseFOA: false,
  scene: '',
  unionid: '', // scene=register时必传，不能为空
  onLoginSuccess: () => {}
}
export default LineLogin
