import React, { Component } from 'react'
import './index.less'
import pic from '../assets/images/h5/Community@2x.png'
import LcTitle from '../module/LcTitle/index.jsx'
import LcInput from '../module/LcInput/index.jsx'
import TipWrap from '../module/TipWrap/index.jsx'
// import Reaptcha from 'reaptcha'
import Recaptcha from 'react-google-invisible-recaptcha' // reaptcha V3
// import { loginSendSmsCode, checkSmsCode, bindMobile } from '../../utils/api.js'
// import { getSeconds } from '../../utils/util.js'
// import { errorCode } from '../../utils/errorCode.js'

import { api, errorCode, config, util } from 'amway-lc-fn'
const { loginSendSmsCode, checkSmsCode, bindMobile } = api
const { getEnvironment } = util

export default class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMsg: '',
      phone: '',
      smsCode: '',
      timer: undefined,
      countdown: 0,
      sendMsgSuccess: false,
      ncSuccess: false,
      step: 0
    }
  }

  componentWillUnmount() {
    if (this.state.timer) clearInterval(this.state.timer)
  }

  handleSendSmsCode = () => {
    if (this.state.countdown) return
    const valid = this.validate()
    if (valid) {
      this.sendSmsCode()
    }
  }

  validate = () => {
    const { phone } = this.state
    if (phone.replace(/\s/g, '').substr(0, 2) !== '09' || phone.replace(/\s/g, '').length !== 10) {
      this.setState({ errorMsg: errorCode['err.businesslogin.phoneType'] })
      return false
    } else {
      this.setState({ errorMsg: '' })
      return true
    }
  }

  onResolved = () => {

  }

  sendSmsCode = () => {
    const { scene } = this.props
    const _params = {
      phoneNumber: this.state.phone.replace(/\s/g, ''),
      scene
    }
    loginSendSmsCode(_params).then(res => {
      if (res.errcode !== '0') {
        const errMessage = errorCode[res.errcode] || errorCode[res.errmsg]
        this.setState({ errorMsg: errMessage })
      } else {
        if (res && res.smsCode) this.setState({ smsCode: res.smsCode })
        this.startTimer()
        this.setState({ sendMsgSuccess: true })
      }
    }).catch(() => {
      this.setState({ errorText: errorCode.NETERROR })
    })
  }

  startTimer = () => {
    let countdown = 60
    const Timer = setInterval(() => {
      if (countdown === 0) {
        clearInterval(Timer)
        this.setState({ countdown: 0 })
      } else {
        this.setState({ countdown })
        countdown--
      }
    }, 1000)
    this.setState({ timer: Timer })
  }

  onVerify = () => {
    const valid = this.validate()
    if (valid) {
      this.setState({ ncSuccess: true })
    } else {
      this.captcha.reset()
    }
  }

  checkSms = () => {
    const valid = this.validate()
    if (!valid) return
    const { phone, smsCode } = this.state
    let errorMsg = ''
    checkSmsCode({ phoneNumber: phone, smsCode }).then(res => {
      if (res === true) {
        errorMsg = ''
        this.submit()
      } else {
        errorMsg = '驗證碼輸入錯誤'
      }
      this.setState({ errorMsg })
    }).catch(err => {
      errorMsg = '系統連線逾時，請重新整理頁面後再試一次'
      this.setState({ errorMsg })
    })
  }

  submit = () => {
    let errorMsg = ''
    const params = {
      ada: this.props.store.userInfo.ada,
      isReplace: 'Y'
    }
    if (this.props.bindInfo.isMaster) {
      params.masterMobile = this.state.phone
    } else {
      params.spouseMobile = this.state.phone
    }
    bindMobile(params).then(res => {
      if (res.ada) { // success
        errorMsg = ''
        this.setState({ step: 1 })
      } else {
        errorMsg = '系統連線錯誤，請稍後再試'
      }
      this.setState({ errorMsg })
    }).catch(err => {
      console.log(err)
      errorMsg = '系統連線逾時，請重新整理頁面後再試一次'
      this.setState({ errorMsg })
    })
  }

  back = () => {
    this.props.updateParent('state', 0)
  }

  render() {
    const { errorMsg, phone, countdown, smsCode, sendMsgSuccess, ncSuccess, step } = this.state
    return (
      <div className='lc-commonWrap lc-bindMobile'>
        {
          step === 0 ? <div>
            <LcTitle onBack={this.back} title="註冊"></LcTitle>
            <div className="loginCenter-form">
              <div className="loginCenter-form-item flex-between">
                <LcInput className="loginCenter-form-item-input" value={phone} placeholder="輸入電話號碼"
                  autoComplete="off" maxLength="10" clearable onChange={val => { this.setState({ phone: val }) }}></LcInput>
                <div className={`get-code ${phone.length && !countdown ? 'active' : ''}`} onClick={this.handleSendSmsCode}>發送 OTP</div>
              </div>
              <div className="loginCenter-form-item">
                <LcInput className="loginCenter-form-item-input" value={smsCode} placeholder="進入 OTP"
                  autoComplete="off" maxLength="10" clearable onChange={val => { this.setState({ smsCode: val }) }}></LcInput>
              </div>
              <div className={`send-code-success-text ${sendMsgSuccess && countdown ? '' : 'hide'}`}>
                <div className="left">
                  <p>OTP 已發送至 {phone} 適用於 {countdown}</p>
                </div>
              </div>
              <div className={`reaptcha-wrap ${phone !== '' && sendMsgSuccess ? 'show' : 'hide'}`}>
                <Recaptcha
                  ref={e => (this.recaptcha = e)}
                  sitekey={config.environment[getEnvironment()].sitekey}
                  onResolved={this.onResolved} />
              </div>
              {
                errorMsg ? <p className="lc-errorMsg">{errorMsg}</p> : ''
              }
              <div className="loginCenter-form-item">
                <div onClick={this.checkSms} className={`loginCenter-form-item-btn ${phone && smsCode && ncSuccess ? 'loginCenter-form-item-btn-active' : ''}`}>綁定手機號賬號</div>
              </div>
              <TipWrap style={{ marginTop: '30px' }}></TipWrap>
            </div>
          </div>
            : <div className="lc-page-success">
              <div className='success-img'>
                <img src={pic} style={{ width: '50%' }} alt='' />
              </div>
              <h4>恭喜!
您已註冊為安利之友 (FOA) 成功地</h4>
              <p>探索為您提供的 200,000 多種產品</p>
              <div onClick={this.props.onBack} className="sucees-btn">
            繼續購物
              </div>
            </div>
        }

      </div>
    )
  }
}
