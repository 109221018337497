import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './index.less'
import pic from '../assets/images/h5/Binding Phone No.@2x.png'
import LcTitle from '../module/LcTitle/pureIndex.jsx'
import FormCreate from '../FormCreate/index.jsx'
import LcInput from '../module/LcInput/index.jsx'
// import TipWrap from '../module/TipWrap/index.jsx'
import Dialog from '../Dialog/index.jsx'
// import Reaptcha from 'reaptcha'
import Recaptcha from 'react-google-invisible-recaptcha' // reaptcha V3

// import { config } from 'amway-lc-fn'
// import { loginSendSmsCode, checkSmsCode, validateMobileNumber, checkIdcard, bindMobile } from '../../utils/api.js'
// import { getSeconds } from '../../utils/util.js'
// import { errorCode } from '../../utils/errorCode.js'

import { api, util, errorCode, config } from 'amway-lc-fn'
const {
  loginSendSmsCode,
  // checkSmsCode,
  validateMobileNumber,
  checkIdcard,
  bindMobile,
  bindComplain
} = api
const { replaceInputValue, getEnvironment } = util
class BindMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMsg: '',
      timer: undefined,
      countdown: 0,
      sendMsgSuccess: false,
      recaptchaType: null,
      ncSuccess: true,
      step: 0,
      identityNumber: '',
      dialog: {
        visible: false,
        type: '',
        title: '',
        content: '',
        confirmText: '立即變更',
        cancelText: '也許以後'
      }
    }
  }

  componentWillUnmount() {
    if (this.state.timer) clearInterval(this.state.timer)
  }

  onResolved = () => { // v3
    const { recaptchaType } = this.state
    const recaptchaToken = this.recaptcha.getResponse()
    if (recaptchaType === 'send') {
      this.sendSmsCode(recaptchaToken)
    }
    // if(recaptchaType==='submit'){
    //   this.checkSms(recaptchaToken)
    // }
  };

    handleSendSmsCode = () => {
      if (this.state.countdown) return
      const valid = this.validate()
      if (valid) {
        this.setState({ recaptchaType: 'send' })
        this.recaptcha.execute() // v3
      } else {
        this.recaptcha.reset()
      }
    }

    validate = () => {
      const { validateFields, getFieldValue } = this.props
      if (!getFieldValue('phone')) return false
      let valide
      validateFields((valid, values) => {
        valide = valid
      })
      if (valide) {
        this.setState({ errorMsg: '' })
      }
      return valide
    }

    sendSmsCode = (recaptchaToken) => {
      const { scene, getFieldValue, setFieldValue } = this.props
      const _params = {
        ada: this.props.ada,
        phoneNumber: getFieldValue('phone').replace(/\s/g, ''),
        recaptchaToken,
        scene
      }
      loginSendSmsCode(_params).then((res) => {
        if (res.code !== undefined) {
          const errMessage = errorCode[res.code] || errorCode[res.message]
          this.setState({ errorMsg: errMessage })
        } else {
          if (res && res.smsCode) setFieldValue('smsCode', res.smsCode)
          this.startTimer()
          this.setState({ sendMsgSuccess: true })
        }
      }).catch(() => {
        this.setState({ errorText: errorCode.NETERROR })
      })
    }

    startTimer = () => {
      let countdown = 60
      const Timer = setInterval(() => {
        if (countdown === 0) {
          clearInterval(Timer)
          this.setState({ countdown: 0 })
        } else {
          this.setState({ countdown })
          countdown--
        }
      }, 1000)
      this.setState({ timer: Timer })
    }

    onVerify = () => {
      const valid = this.validate()
      if (valid) {
        this.setState({ ncSuccess: true })
      } else {
        this.captcha.reset()
      }
    }

    checkSms = () => {
      const valid = this.validate()
      if (!valid) return
      const { ncSuccess } = this.state
      const { getFieldValue } = this.props
      if (!getFieldValue('phone') || !getFieldValue('smsCode') || !ncSuccess) return
      this.checkIsBind()
      // this.setState({recaptchaType:'submit'})
      // this.recaptcha.execute() // v3
    }

    checkIsBind = () => {
      const { getFieldValue } = this.props
      let errorMsg = ''
      const params = {
        ada: this.props.ada,
        mobile: getFieldValue('phone').replace(/\s/g, ''),
        mobileFlag: 'Y'
      }
      const { dialog } = this.state
      validateMobileNumber(params)
        .then((data) => {
          if (data.code === undefined) {
            if (
              data.allow &&
                        data.masterBindFoa === 'N'
            ) {
              // 不需要校验
              this.submit()
            } else if (!data.allow && data.otherBindAda) {
              // 非FOA已经绑定此手机号，需要校验身份证后6位
              const phoneLast4 = data.otherBindAda.substring(data.otherBindAda.length - 4, data.otherBindAda.length)
              dialog.title = `此手機號碼已綁定了安麗編號 尾數為“${phoneLast4}”`
              dialog.content =
                            '如果您想綁定此手機號碼，請輸入身份證字號末 6 位數字'
              dialog.type = 'identityNumber'
              dialog.visible = true
              this.setState({ dialog })
            } else if (data.masterBindFoa === 'Y') {
              // 绑定的手机号被FOA绑定过
              // 不需要校验
              // this.submit();
              dialog.title = '此手機號已綁定 FOA 賬號'
              dialog.content =
                            '如果您想繼續，該 FOA 帳戶將被刪除。此操作無法撤消。'
              dialog.type = 'foa'
              dialog.visible = true
              this.setState({ dialog })
            }
          } else {
            errorMsg =
            errorCode[data.code] || '系統連線逾時，請重新整理頁面後再試一次'
          }
          this.setState({ errorMsg })
        })
        .catch((err) => {
          errorMsg = '系統連線逾時，請重新整理頁面後再試一次'
          this.setState({ errorMsg })
        })
    }

    submit = () => {
      const { getFieldValue, hasSuccessPage, onSuccess } = this.props
      let errorMsg = ''
      const params = {
        ada: this.props.ada,
        phoneNumber: getFieldValue('phone').replace(/\s/g, ''),
        smsCode: getFieldValue('smsCode'),
        isReplace: 'Y'
      }
      if (this.props.isMaster) {
        params.masterMobile = getFieldValue('phone').replace(/\s/g, '')
      } else {
        params.spouseMobile = getFieldValue('phone').replace(/\s/g, '')
      }
      bindMobile(params)
        .then((res) => {
          if (res.ada) {
            // success
            errorMsg = ''
            if (hasSuccessPage) {
              this.setState({ step: 1 })
            } else {
              onSuccess(res)
            }
          } else {
            errorMsg = errorCode[res.code] || '系統連線錯誤，請稍後再試'
          }
          this.setState({ errorMsg })
        })
        .catch((err) => {
          console.log(err)
          errorMsg = '系統連線逾時，請重新整理頁面後再試一次'
          this.setState({ errorMsg })
        })
    }

    onConfirm = () => {
      const { dialog } = this.state
      if (dialog.type === 'identityNumber') {
        this.identityNumberConfirm()
      } else {
        this.submit()
      }
      dialog.visible = false
      this.setState({ dialog })
    }

    identityNumberConfirm = () => {
      const { getFieldValue } = this.props
      const { identityNumber } = this.state
      const _param = {
        ada: this.props.ada,
        phone: getFieldValue('phone').replace(/\s/g, ''),
        idCard: identityNumber,
        isMasterCard: ''
      }
      checkIdcard(_param)
        .then((data) => {
          if (data === true) {
            this.showIdentityNumber = false
            this.isCheckIdentityNumber = true
            this.bindComplainMobile()
            return
          }
          this.setState({ errorMsg: '身分驗證失敗，請重新輸入' })
        })
        .catch((err) => {
          this.setState({
            errorMsg: '系統連線逾時，請重新整理頁面後再試一次'
          })
        })
    }

    bindComplainMobile = () => {
      const { getFieldValue } = this.props
      let errorMsg = ''
      const params = {
        ada: this.props.ada,
        scene: 'only'
      }
      if (this.props.isMaster) {
        params.masterMobile = getFieldValue('phone').replace(/\s/g, '')
        params.masterIdcard = this.state.identityNumber
      } else {
        params.spouseMobile = getFieldValue('phone').replace(/\s/g, '')
        params.spouseIdcard = this.state.identityNumber
      }
      bindComplain(params).then(res => {
        if (res.ada) {
          errorMsg = ''
          this.setState({ step: 1 })
        } else if (res.code === 'err.businesslogin.ada.unactivated') {
          errorMsg = 'ada账户未激活'
        } else if (res.code === 'err.pnBinder.isNotFound') {
          errorMsg = '查詢不到帳號資訊'
        } else if (res.code === 'err.pnBinder.master.mobilePhoneIsNotEquals') {
          errorMsg = '手機號不存在，無法通過申述替換！'
        } else {
          errorMsg = '系統連線錯誤，請稍後再試'
        }
        this.setState({ errorMsg })
      }).catch((err) => {
        this.setState({
          errorMsg: '系統連線逾時，請重新整理頁面後再試一次'
        })
      })
    }

    onCancel = () => {
      const { dialog } = this.state
      dialog.visible = false
      this.setState({ dialog })
    }

    render() {
      const {
        errorMsg,
        countdown,
        sendMsgSuccess,
        ncSuccess,
        step,
        dialog,
        identityNumber
      } = this.state
      const { getFieldDecorator, getFieldValue, onBack, onSuccess, ada } = this.props
      const phoneValidator = (rule, value, callback) => {
        if (
          value && value.replace(/\s/g, '').substr(0, 2) === '09' &&
              value.replace(/\s/g, '').length === 10
        ) {
          callback()
        } else {
          return callback('手機號碼格式錯誤')
        }
      }
      return (
        <div className='lc-commonWrap lc-bindMobile'>
          {step === 0 ? (
            <div>
              <LcTitle
                onBack={onBack}
                title='綁定手機號賬號'
              ></LcTitle>
              <div className='loginCenter-form'>
                <div className='loginCenter-form-item flex-between'>
                  <span>直銷商編號</span>
                  <span>{ada}</span>
                </div>
                <div className='loginCenter-form-item flex-between'>
                  {getFieldDecorator('phone', { rules: [{ validator: phoneValidator }], format: replaceInputValue })(
                    <LcInput
                      className='loginCenter-form-item-input'
                      placeholder='請輸入手機號碼'
                      autoComplete='off'
                      maxLength='10'
                      clearable
                    ></LcInput>
                  )}
                  <div
                    className={`get-code ${
                      getFieldValue('phone').length && !countdown
                                            ? 'active'
                                            : ''
                                    }`}
                    onClick={this.handleSendSmsCode}
                  >
                    {sendMsgSuccess
                      ? '重新發送'
                      : '發送簡訊驗證碼'}
                  </div>
                </div>
                <div className='loginCenter-form-item'>
                  {getFieldDecorator('smsCode', { rules: [] })(
                    <LcInput
                      className='loginCenter-form-item-input'
                      placeholder='請輸入簡訊驗證碼'
                      autoComplete='off'
                      maxLength='10'
                      clearable
                    ></LcInput>
                  )}
                </div>
                <div
                  className={`send-code-success-text ${
                                    sendMsgSuccess && countdown ? '' : 'hide'
                                }`}
                >
                  <div className='left'>
                    <p>
                                        已發送簡訊驗證碼，如果沒有收到,{' '}
                      {countdown}秒後您可以點選"重新發送"
                    </p>
                  </div>
                </div>
                <div
                  className={`reaptcha-wrap ${
                    getFieldValue('phone') !== '' && sendMsgSuccess
                                        ? 'show'
                                        : 'hide'
                                }`}
                >
                  {/* <Reaptcha
                    ref={(e) => (this.captcha = e)}
                    sitekey={config.sitekey}
                    onVerify={this.onVerify}
                  /> */}
                  <Recaptcha
                    ref={e => (this.recaptcha = e)}
                    sitekey={config.environment[getEnvironment()].sitekey}
                    onResolved={this.onResolved} />
                </div>
                {errorMsg ? (
                  <p className='lc-errorMsg'>{errorMsg}</p>
                ) : (
                  ''
                )}
                <div className='loginCenter-form-item'>
                  <div
                    onClick={this.checkSms}
                    className={`loginCenter-form-item-btn ${
                      getFieldValue('phone') && getFieldValue('smsCode') && ncSuccess
                                            ? 'loginCenter-form-item-btn-active'
                                            : ''
                                    }`}
                  >
                                    立即綁定
                  </div>
                </div>
                {/* <TipWrap style={{ marginTop: '30px' }}></TipWrap> */}
              </div>
              <Dialog
                title={dialog.title}
                content={dialog.content}
                confirmText='確認'
                confirmAble={identityNumber.length === 6 || dialog.type === 'foa'}
                cancelText={dialog.cancelText}
                confirm={this.onConfirm}
                cancel={this.onCancel}
                visible={dialog.visible}
              >
                {dialog.type === 'identityNumber' ? (
                  <LcInput
                    className='loginCenter-form-item-input'
                    value={identityNumber}
                    placeholder=''
                    autoComplete='off'
                    maxLength='10'
                    onChange={(e) => {
                      this.setState({ identityNumber: e.target.value })
                    }}
                  ></LcInput>
                ) : (
                  ''
                )}
              </Dialog>
            </div>
          ) : (
            <div className='lc-page-success'>
              <div className='success-img'>
                <img src={pic} style={{ width: '50%' }} alt='' />
              </div>
              <h4>手機號碼綁定成功</h4>
              <p>下次可透過手機號碼輕鬆登入</p>
              <div onClick={onSuccess} className='sucees-btn'>
                            完成綁定手機帳號
              </div>
            </div>
          )}
        </div>
      )
    }
}
BindMobile.propTypes = {
  hasSuccessPage: PropTypes.bool,
  isMaster: PropTypes.bool,
  scene: PropTypes.string,
  ada: PropTypes.string,
  onBack: PropTypes.func,
  onSuccess: PropTypes.func
}
BindMobile.defaultProps = {
  hasSuccessPage: false,
  isMaster: false,
  scene: '',
  ada: '',
  onBack: () => {},
  onSuccess: () => {}
}
export default FormCreate(BindMobile)
