// @flow
import React, { useContext } from 'react'
import { Context } from '../AppContext.js'
import './index.less'
const InformationNotify = (props) => {
  const { setVisible, personalInfo } = useContext(Context)
  return (
    <div className={`lc-InformationNotify ${props.className}`}>
      <div className="lc-InformationNotify-title">
      個資告知
        <i onClick={() => { setVisible(false) }} className="close iconfont icon-close_light"></i>
      </div>
      <div className='lc-InformationNotify-ctx' dangerouslySetInnerHTML={personalInfo !== null ? { __html: personalInfo.more_details } : { __html: '' }}>
      </div>
      {/* <ul>
        <li>安麗日用品股份有限公司(下稱本公司)依個人資料保護法等相關法令規定，在線上購物配送與售後服務的特定目的範圍内蒐集您所提供的姓名、電話、地址、信用卡、電子信箱；針對您所提供的個人資料，本公司只會在達成上述目的及業務統計分析所需的範圍內處理或利用，並於上述特定目的消失且經您提出書面要求予以刪除。</li>
        <li>本公司蒐集到您的個人資料，原則上僅會以電子檔或纸本形式供本公司、提供連結之直銷商、委外廠商或與本公司具有合作、委任等關係之第三人處理及利用；惟由於本公司屬跨國企業業並允許國際推薦，為達成與您之間的契約目的或促進/保存您的合法權益，您的個人資料亦會於本公司各集團企業所在地被處理及利用，包括本公司相關活動或產品訊息之提供與介紹。</li>
        <li>本公司保有您的個人資料，基於個人資料保護法之規定，您可以依法行使下述的權利。(1)查詢或請求閱覽您的個人資料。(2)請求製給您的個人資料複製本。(3)請求補充或更正您的個人資料。(4)請求停止蒐集、處理或利用您的個人資料。(5)請求刪除您的個人資料。如您欲行使上述提及的相關權利時，或拒絕本公司之行銷使用，亦可撥打本公司顧客服務專線（03）353-7800，將有專人為您服務。</li>
        <li>本公司基於上述原因而需蒐集、處理或利用您的個人資料時，您可以自由選擇是否提供您的個人資料。若您選擇不提供個人資料或是提供不完全時，將會影響您無法使用此線上購物配送服務。(201903A版)</li>
      </ul> */}
    </div>
  )
}
export default InformationNotify
