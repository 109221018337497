import React, { useState, useEffect, useContext } from 'react'
import './index.less'
import pic from '../assets/images/h5/Person Check@2x.png'
import LcTitle from '../module/LcTitle/index.jsx'
import FormCreate from '../FormCreate/index.jsx'
import LcInput from '../module/LcInput/index.jsx'
import YearSelect from '../module/YearSelect/index.jsx'
import { Context } from '../AppContext.js'

// import { forgetAda } from '../../utils/api.js'
import { api } from 'amway-lc-fn'
const { forgetAda } = api

const ForgetAda = (props) => {
  const [errorMsg, setErrMsg] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [step, setStep] = useState(0)
  const [ada, setAda] = useState('')
  const [showTip, setShowTip] = useState(false)
  const { setState } = useContext(Context)
  const { getFieldDecorator, getFieldValue, validateFields } = props
  useEffect(() => {

  }, [])
  const back = () => {
    setState('Login')
  }
  const dataChange = (value) => {
    setDateOfBirth(value)
  }
  const submit = () => {
    setErrMsg('')
    if (!getFieldValue('idCard') || !dateOfBirth) return
    validateFields((valid, values) => {
      if (valid) {
        let errorMsg = ''
        forgetAda({ idCard: values.idCard, dateOfBirth }).then(res => {
          if (res && typeof res === 'string') { // success
            setAda(res)
            setStep(1)
            errorMsg = ''
          } else if (res && res.code !== '0') {
            if (res.message === 'err.pwdManage.notFound.ada') {
              errorMsg = '資料驗證無效，請重新確認'
            } else if (res.message === 'err.pwdManage.notMatch' || res.message === 'err.pwdManage.notMatch') {
              errorMsg = '資料驗證無效，請重新確認'
            } else if (res.message === 'err.pwdManage.notFound.contact') {
              errorMsg = '您未設置手機號碼/email，詳情請洽客服(03)353-7800'
            } else if (res.message === 'err.param.password.isEmpty') {
              errorMsg = '您需要開卡後才能進行該操作'
            }
          } else {
            errorMsg = '系統連線錯誤，請稍後再試'
          }
          setErrMsg(errorMsg)
        }).catch(err => {
          console.log(err)
          errorMsg = '系統連線逾時，請重新整理頁面後再試一次'
          setErrMsg(errorMsg)
        })
      }
    })
  }
  const copy = () => {
    const input = document.createElement('input')
    input.value = ada
    input.style = {
      position: 'absolute',
      top: '0',
      left: '0',
      opacity: '0'
    }
    document.body.appendChild(input)
    input.select()
    document.execCommand('Copy')
    document.body.removeChild(input)
    setShowTip(true)
    setTimeout(() => {
      setShowTip(false)
    }, 3000)
  }
  const idCardValidator = (rule, value, callback) => {
    if (value.length < 8) {
      return callback('輸入資料錯誤')
    }
  }
  return (
    <div className='lc-commonWrap lc-forgetAda'>
      {
        step === 0 ? <div>
          <LcTitle title="忘記編號"></LcTitle>
          <div className="loginCenter-form">
            <div className="loginCenter-form-item">
              <p className="loginCenter-form-item-label">身份證字號</p>
              {getFieldDecorator('idCard', { rules: [{ validator: idCardValidator }] })(<LcInput className="loginCenter-form-item-input" placeholder="請輸入身份證字號"
                autoComplete="off" maxLength="10" clearable></LcInput>)}
            </div>
            <div className="loginCenter-form-item">
              <p className="loginCenter-form-item-label">民國出生年月日</p>
              <YearSelect onChange={(val) => { dataChange(val) }}></YearSelect>
            </div>
            {
              errorMsg ? <p className="lc-errorMsg">{errorMsg}</p> : ''
            }
            <div className="loginCenter-form-item">
              <div onClick={submit} className={`loginCenter-form-item-btn ${getFieldValue('idCard') && dateOfBirth ? 'loginCenter-form-item-btn-active' : ''}`}>驗證身份</div>
            </div>
          </div>
        </div>
          : <div className="lc-page-success lc-forgetAda-success">
            <div className={`copy-ada-success ${showTip ? 'copy-ada-success-show' : ''}`}>安麗編號複製成功</div>
            <div className='success-img'>
              <img src={pic} style={{ width: '50%' }} alt='' />
            </div>
            <h4>身份驗證成功</h4>
            <p>您的安麗編號是</p>
            <div className="ada-ctx">
              <span>{ada}</span>
              <i onClick={copy} className="iconfont icon-Copy"></i>
            </div>
            <div onClick={back} className="sucees-btn">
              返回登入頁面
            </div>
          </div>
      }

    </div>
  )
}
export default FormCreate(ForgetAda)
